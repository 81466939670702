import React from 'react'
import ReactLoading from 'react-loading';

function Loading() {
  return (
    <div>
        <ReactLoading type="spinningBubbles" color="black" height={'10%'} width={'10%'} />
    </div>
  )
}

export default Loading