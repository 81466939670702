// import './App.scss';
import { useEffect, useState } from 'react';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// import ProductImagesSlider from './components/product-images-slider';
// import { productImages } from './assets'
import ProductImagesSlider from './product-image-slider';


function Slider({images, singlePhoto}) {

    const [pimage, setPimage] = useState([])

    let pimages = [];
    pimages.push(singlePhoto?.url)
    // console.log(images)
    // console.log(pimage)

    useEffect(() => {
        images && images.map((item) => {
            // console.log(item.url)
            pimages.push(item.url)
            return pimages
        })
        // console.log(pimages)
        setPimage(pimages)
    },[images])

    // console.log(pimages)

    // const productImages = [
    //     'https://res.cloudinary.com/dbogjiyfs/image/upload/v1680018698/products/qlqasebbmhowt0xykr2i.jpg',
    //     require('../../assets/img/shop/2.jpg'),
    //     require('../../assets/img/shop/3.jpg'),
    //     require('../../assets/img/shop/4.jpg'),
    //     require('../../assets/img/shop/5.jpg'),
    // ]

    return (
        <div style={{
            // height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: 400
        }}>
            <div className='imageSliderThum' style={{
                // width: '500px',
                backgroundColor: '#fff',
                padding: '20px'
            }}>
                <ProductImagesSlider images={pimage} />
            </div>
        </div>
    );
}

export default Slider;