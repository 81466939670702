import axios from "axios";
import { useState, useContext, createContext, useEffect } from "react";
import { useAuth } from "./auth";

const CartContext = createContext();
const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [auth, setAuth] = useAuth()
  console.log(auth)

  const getUserCart = async() => {
    try {
      const data = localStorage.getItem("auth");
      const userData = JSON.parse(data);
      console.log(userData)
      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/cart/get-cart/${userData.user._id}`)
      console.log(res)
      let cartData = [];
      res.data.cart.map((item) => {
        const price = item?.products?.price - ( item?.products?.discount || 0)
        // console.log(price)
        cartData.push({
          cid: item._id,
          products: item.products,
          quantity: item.quantity,
          totalPrice: price * item.quantity,
          allSize: item.allSize,
          size: item.size
        })
        return cartData
      })
      console.log(cartData)
      setCart(cartData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserCart();
  }, []);

  return (
    <CartContext.Provider value={[cart, setCart]}>
      {children}
    </CartContext.Provider>
  );
};

// custom hook
const useCart = () => useContext(CartContext);

export { useCart, CartProvider };
