import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from '../context/auth';
import { useCart } from '../context/cart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function CartItemPage({active, setActive}) {

  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart()

  console.log(cart)

  const navigate = useNavigate();

  const onSingleItemHandler = (slug) => {
    navigate(`/product/${slug}`);
  };


  const removeCartItem = async (pid) => {
    try {
      console.log(pid)
      const res = await axios.delete(`${process.env.REACT_APP_API}/api/v1/cart/delete-cart/${pid}`)
      console.log(res)
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item.cid === pid);
      console.log(index)
      myCart.splice(index, 1);
      setCart(myCart);
      if (res.data.success) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDecreaseHandler = async (id, price, p) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/cart/update-cart/${id}`, { qtyIncDec: false, price })
      console.log(res)
      const priceP = p?.products?.price - ( p?.products?.discount || 0)
      setCart(cart =>
        cart.map((item) => {
          return id === item.cid ? { ...item, quantity: res?.data?.cart?.quantity, totalPrice: res?.data?.cart?.quantity * priceP } : item
        }))
    } catch (error) {
      console.log(error)
    }
  }

  const onIncreaseHandler = async (id, price, p) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/cart/update-cart/${id}`, { qtyIncDec: true, price })
      console.log(res)
      const priceP = p?.products?.price - ( p?.products?.discount || 0)
      setCart(cart =>
        cart.map((item) => {
          return id === item.cid ? { ...item, quantity: res?.data?.cart?.quantity, totalPrice: res?.data?.cart?.quantity * priceP } : item
        }))
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeSizeHandler = async (id, size) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/cart/update-cartsize/${id}`, { size })
      console.log(res)
      setCart(cart =>
        cart.map((item) => {
          return id === item.cid ? { ...item, size: res?.data?.cart?.size } : item
        }))
    } catch (error) {
      console.log(error)
    }
  }

  const onNextHandler = () => {
    let temp = 0;
    for (const item of cart) {
      if (!item.size || item.size === "Select Size") {
        temp = 0
        toast.error(`Please Select ${item.products.name} size`)
        break
      }
      temp = 1
    }
    
    if (temp === 1) {
      setActive(active + 1)
    }
  }

  return (
      <div className="cart-page">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              {cart?.map((p) => (
                <div className="row flex-row m-2 p-2" style={{ boxShadow: "1px 1px 10px 1px grey" }} key={p.cid}>
                  <div className="col-md-4" onClick={() => onSingleItemHandler(p.products.slug)}>
                    <img
                      src={p.products.photo ? p.products.photo.url : ""}
                      className="card-img-top"
                      alt={p.name}
                      width="100%"
                      height={"170px"}
                    />
                  </div>
                  <div className="col-md-7">
                    <div onClick={() => onSingleItemHandler(p.products.slug)}>
                      <p className='m-0' style={{ fontSize: 20 }}>{p.products.name}</p>
                      <p className='m-0'>{p.products.description.substring(0, 30)}</p>
                      {/* <p className='m-0' style={{ fontSize: 18 }}>₹ {p.products.price}</p> */}
                      {p?.products?.discount ? (
                              <div style={{display: "flex", alignItems: "center"}}>
                              <p className="card-text" style={{ fontSize: 20, marginBottom: 0 }}>₹{(p?.products?.price - p?.products?.discount).toFixed()} </p>
                              <p className="card-text discountPrice" style={{ fontSize: 15, marginBottom: 0 }}> ₹{p?.products?.price}</p>
                              <p className="card-text" style={{ fontSize: 16, color: 'red', marginBottom: 0 }}>{p?.products?.discountRate}%</p>
                              </div>
                            ) : (
                              <p className="card-text" style={{ fontSize: 18, marginBottom: 0 }}>
                                ₹{p?.products?.price}
                            </p>
                            )}
                    </div>
                    <div>
                      <select className="form-select my-1" aria-label="Default select example"
                        onChange={(e) => onChangeSizeHandler(p.cid, e.target.value)}
                        value={p.size}
                        style={{ width: 150 }}>
                        <option>Select Size</option>
                        {p.allSize.map((item) => {
                          return <option key={item} value={item}>{item}</option>
                        })}
                      </select>
                    </div>
                    <div className="d-flex">
                      <button className="btn btn-outline-secondary" style={{ width: 25, height: 30, padding: 0 }}
                        disabled={p.quantity < 2}
                        onClick={() => onDecreaseHandler(p.cid, p.products.price, p)}>
                        <RemoveIcon />
                      </button>
                      <input
                        className="form-control mx-2"
                        style={{ width: 40, height: 30 }}
                        value={p.quantity}
                        onChange={() => p.quantity}
                      />
                      <button className="btn btn-outline-secondary" style={{ width: 25, height: 30, padding: 0 }}
                        onClick={() => onIncreaseHandler(p.cid, p.products.price, p)}>
                        <AddIcon />
                      </button>

                    </div>
                    {<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 0 }}>
                      <h5>TotalPrice : {p.totalPrice.toFixed()}</h5>
                    </div>}
                  </div>
                  <div className="col-md-1 cart-remove-btn text-end">
                    <DeleteIcon onClick={() => removeCartItem(p.cid)} />
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-2"></div>
          </div>
          <div style={{display: "flex", justifyContent: "center"}}>
          <button
          onClick={onNextHandler}
          style={{ margin: 20, borderRadius: 10, padding: 10 }}
        >
          Next <ArrowForwardIcon />
        </button>
        </div>
        </div>
      </div>
  )
}

export default CartItemPage