import React, { useContext, useState } from 'react'
import Layout from '../components/layout/Layout'
import { MultiStepForm, Step } from 'react-multi-form'
import CartItemPage from '../components/CartItemPage'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Address from '../components/Address';
import { OrderContext } from '../context/orderContext';
import Payment from '../components/Payment';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useCart } from '../context/cart';
import { useAuth } from '../context/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Confirmation from '../components/Confirmation';

function BuyNow() {
    const [active, setActive] = useState(1)
    const [order, setOrder] = useState()
    const [cart, setCart] = useCart();
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    console.log('cart========> ', cart)
    const { oldAddress, 
      newAddress,
      deliveryAdd,
      paymentOption} = useContext(OrderContext)

       const deliveryAddress = deliveryAdd === "old" ? oldAddress : newAddress;
      //  console.log("deliveryAddress", deliveryAddress)
       console.log("payment option", paymentOption)

       const onDeleteHandler = async (pid) => {
        try {
          console.log(pid)
          const res = await axios.delete(`${process.env.REACT_APP_API}/api/v1/cart/delete-cart/${pid}`)
          console.log(res)
          let myCart = [...cart];
          let index = myCart.findIndex((item) => item.cid === pid);
          console.log(index)
          myCart.splice(index, 1);
          setCart(myCart);
          // if (res.data.success) {
          //   toast.success(res.data.message)
          // }
        } catch (error) {
          console.log(error);
        }
      };

    const onOrderHandler = () => {
      if (!paymentOption) {
        toast.error("Please Select Payment Option.")
        return
      }
        try {
          console.log('cart========> ', cart)
          let data = [];
          const res = cart.map((item) => {
            data.push({
              product: item.products,
              quantity: item.quantity,
              totalPrice: item.totalPrice,
              size: item.size
            })
            return data
          })
          console.log("order data ====00000", data)
          console.log('auth========> ', auth?.user)
          confirmAlert({
            title: 'Order Placed.',
            message: 'Are you sure to placed this order.',
            buttons: [
              {
                label: 'Yes',
                onClick: async() => {
                  const result = await axios.post(`${process.env.REACT_APP_API}/api/v1/product/checkout`, {
                    cart: data,
                    user: auth?.user?._id,
                    payment: paymentOption,
                    deliveryAddress: deliveryAddress
                  })
                  if (result?.data?.success) {
                    for (const cart_data of cart) {
                      await onDeleteHandler(cart_data.cid)
                    }
                  }
                  setCart([])
                  console.log(result)
                  setOrder(result?.data?.order)
                  setActive(active + 1)
                  toast.success("Your Order Placed Successfully.")
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
          
        } catch (e) {
          console.log(e)
        }
    }

  return (
    <Layout>
        
        <div className='container mt-5'>
        <MultiStepForm activeStep={active}>
        <Step label='Cart'>
          <CartItemPage active={active} setActive={setActive}/>
        </Step>
        <Step label='Address'>
          <Address active={active} setActive={setActive}/>
        </Step>
        <Step label='payment'>
          <Payment active={active} setActive={setActive} onOrderHandler={onOrderHandler}/>
        </Step>
        <Step label='confirmation'>
          <h2 className='text-center'>Your Order is confirmed!</h2>
          <h3 className='text-center'>Thanks for your order!</h3>
          <Confirmation deliveryAddress={deliveryAddress} order={order}/>
          <div style={{display: 'flex', justifyContent: "center"}}> 
            <button className='btn btn-warning m-3' onClick={() => navigate("/dashboard/orders")}>Go to Order Page</button>
          </div>
        </Step>
      </MultiStepForm>
      {/* <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {active > 1 && active < 4 && (
        <button style={{ margin: 20, borderRadius: 10, padding: 10 }} onClick={() => setActive(active - 1)}> <ArrowBackIcon /> Previous</button>
      )}
      {active < 3 && (
        <button
          onClick={() => setActive(active + 1)}
          style={{ margin: 20, borderRadius: 10, padding: 10 }}
        >
          Next <ArrowForwardIcon />
        </button>
      )}
      {active === 3 && (
        <button
        className='btn btn-warning'
          onClick={onOrderHandler}
          style={{ margin: 20, borderRadius: 10, padding: 10 }}
        >
          Order Now
        </button>
      )}
      </div> */}
        </div>
    </Layout>
  )
}

export default BuyNow