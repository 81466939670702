import React from 'react'
import Layout from '../../components/layout/Layout'

function Dashboard() {
  return (
    <Layout>
        <h1>Dash</h1>
        <h1>Dash</h1>
        <h1>Dash</h1>
        <h1>Dash</h1>
        <h1>Dash</h1>
        <h1>Dash</h1>
    </Layout>
  )
}

export default Dashboard