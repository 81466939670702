import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function CatFeature() {
    const navigate = useNavigate()
  
    const onMensCategory = () => {
        navigate("/category/640ddf90e40d31b028b57dc6")
    }

    const onWomensCategory = () => {
        navigate("/category/640ddfa2e40d31b028b57dca")
    }

    const onKidsCategory = () => {
        navigate("/category/640ddfcbe40d31b028b57dce")
    }
    
  return (
      <div className='container'>
          <h2 className='mt-4 text-center'>Our Popular Category</h2>
          <hr className="mx-auto hr" />
          {/* <hr style={{width: 200, margin: "auto", marginTop: 10, marginBottom: 10}} className="text-center"/> */}
          <div className="row">
            <div className="col-md-4" style={{padding: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <div className='home mensBack CatFeature'
                      style={{
                          padding: 10, height: 150, borderRadius: 16,
                          boxShadow: "2px 2px 5px black"
                      }} onClick={onMensCategory}>
                          <h2>Mens Collections</h2>
                  </div>
            </div>
            <div className="col-md-4" style={{padding: 10, display: "flex", alignItems: "center", justifyContent: "center",}}>
                  <div className='home womensBack CatFeature' style={{padding: 10, height: 150,borderRadius: 16,
                          boxShadow: "2px 2px 5px black"}} onClick={onWomensCategory}>
                          <h2>Womens Collections</h2>
                  </div>
            </div>
            <div className="col-md-4" style={{padding: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <div className='home kidsBack CatFeature' style={{padding: 10, height: 150, borderRadius: 16,
                          boxShadow: "2px 2px 5px black"}} onClick={onKidsCategory}>
                          <h2>Kids Collections</h2>
                  </div>
            </div>
          </div>
    </div>
  )
}

export default CatFeature