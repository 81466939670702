import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Loading from "../components/Loading";
import CartItem from "../components/CartItem";

function OfferPage() {

    const { id } = useParams();
    const [product, setProduct] = useState([])
    const [offerName, setOfferName] = useState("")
    const [loading, setLoading] = useState(false)
    const [offers, setOffers] = useState()
    const navigate = useNavigate()

    console.log("products ------->>>", product)

    
  
  const getSingleOffer = async() => {
      try {
          setLoading(true)
          const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/offer/get-offer/${id}`)
          console.log('Single offer res: ', res);
          
          setProduct(res?.data?.offer?.product)
          setOfferName(res?.data?.offer?.couponCodeName)
          setLoading(false)
      } catch (error) {
          console.log('error: ', error);
          setLoading(false)
      }
  }
    
  const getOffers = async() => {
    try {
        setLoading(true)
        const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/offer/get-offer`)
        console.log('res: ', res);
        setOffers(res?.data?.offers)
        setLoading(false)
    } catch (error) {
        console.log('error: ', error);
        setLoading(false)
    }
}
    
  useEffect(() => {
      getSingleOffer();
      getOffers();
  }, [id])

  return (
    <Layout>
        <section id="featured" className="my-5 pb-5">
                <div className="text-center mt-5 py-5 mr-5">
                    
                    <h3>{offerName}</h3>
                    <hr className="mx-auto hr" />
                </div>
                <div className="row mx-auto container-fluid">
                <div style={{marginLeft: 650}}>
                    {loading && <Loading />}
                </div>
                    <CartItem products={product}/>
                </div>
            </section>
    </Layout>
  );
}

export default OfferPage;
