import React, { useState } from 'react'
import Layout from '../components/layout/Layout'
import '../styles/contact.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

function Contact() {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [message, setMessage] = useState('')

  const onSubmitHandler = async(e) => {
    e.preventDefault();
    try{
      const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/contact/contact`, {
        firstName,
        lastName,
        email,
        mobile,
        message
      });
      console.log(res)
      if (res?.data?.success) {
        toast.success('Message Send Successfully.')
        setFirstName("")
        setLastName("")
        setEmail("")
        setMobile("")
        setMessage("")
      }
      if (res?.response?.data?.message) {
        toast.error(res?.response?.data?.message)
        console.log(res?.response?.data?.message)
      }
    }catch(e){
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e?.response?.data?.message)
        console.log(e?.response?.data?.message)
      }
    }
  }

  return (
    <Layout title="Fashion Avenue - Contact">
        <div className="contactUS">
      <div className="title">
        <h2>Get Contact Info</h2>
      </div>
      <div className="box">
        <div className="contact form">
          <h3>Send a Message</h3>
          <form action="">
            <div className="formBox">
              <div className="row50">
                <div className="inputBox">
                  <span>First Name</span>
                  <input type="text" placeholder="Enter First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="inputBox">
                  <span>Last Name</span>
                  <input type="text" placeholder="Enter Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
              </div>

              <div className="row50">
                <div className="inputBox">
                  <span>Email</span>
                  <input type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div> 
                <div className="inputBox">
                  <span>Mobile</span>
                  <input type="text" placeholder="Enter Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                </div>
              </div>

              <div className="row100">
                <div className="inputBox">
                  <span>Massage</span>
                  <textarea placeholder="write your Message here..." value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
              </div>

              <div className="row100">
                <div className="inputBox">
                  <input type="submit" value="Send" onClick={(e) => onSubmitHandler(e)}/>
                </div>
              </div>

            </div>
          </form>
        </div>


        <div className="contact info">
            <h3>Contact Info</h3>
            <div className="infoBox">
                <div>
                    <PlaceIcon style={{ color: 'white', margin: 5 }}/>
                    <p>76, Sahajananda soc., Surat, <br />INDIA</p>
                </div>
                <div>
                    <EmailIcon style={{ color: 'white', margin: 5 }}/>
                    <a href="mailto:fashionavenue.j2a@gmail.com">fashionavenue.j2a@gmail.com</a>
                </div>
                <div>
                    <PhoneIcon style={{ color: 'white', margin: 5 }}/>
                    <a href="tel: 02616985325">0261 698 5325</a>
                </div>
                <ul className="sci">
                    {/* <li><a href="#"><ion-icon name="logo-facebook"></ion-icon></a></li>
                    <li><a href="#"><ion-icon name="logo-twitter"></ion-icon></a></li>
                    <li><a href="#"><ion-icon name="logo-linkedin"></ion-icon></a></li>
                    <li><a href="#"><ion-icon name="logo-instagram"></ion-icon></a></li> */}
                </ul>
            </div>
        </div>


        {/* <div className="contact map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1662.6740308
            237167!2d72.86638818757386!3d21.175879387291804!2m3!1f0!2f0!3f0!3m2!1i1024!2i76
            8!4f13.1!3m3!1m2!1s0x3be04f6c161872a7%3A0x36bc73e9bec0947a!2sSahajanand%20Soc!5
            e0!3m2!1sen!2sin!4v1680424006414!5m2!1sen!2sin" style=
            "border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-
            downgrade"></iframe>
        </div> */}
      </div>
    </div>

    </Layout>
  )
}

export default Contact