import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../components/layout/Layout'
import { MultiStepForm, Step } from 'react-multi-form'
import axios from 'axios'

function SingleOrderPage() {

    const [active, setActive] = useState(1)
    const [data, setData] = useState()
    const { id } = useParams()
    console.log(id)

    const navigate = useNavigate()

    const getUserOrders = async () => {
        try {
          const result = await axios.get(
            `${process.env.REACT_APP_API}/api/v1/auth/orders/${id}`
          );
          console.log(result);
          setData(result?.data);
          if (result?.data?.status === "Not Process") {
            setActive(1)
          }
          if (result?.data?.status === "Processing") {
            setActive(2)
          }
          if (result?.data?.status === "Shipped") {
            setActive(3)
          }
          if (result?.data?.status === "Delivered") {
            setActive(5)
          }
          if (result?.data?.status === "Cancel") {
            setActive(0)
          }
        } catch (e) {
          console.log(e);
        }
    };

    useEffect(() => {
        getUserOrders()
    },[])

    const onSingleItemHandler = (slug) => {
        navigate(`/product/${slug}`);
      }

      const getTotalAmount = (item) => {
        let amount = 0;
        const res = data?.products && data?.products.map((item) => {
          amount = amount + item.totalPrice;
          return amount;
        });
        return amount;
      };


  return (
    <div>
        <Layout>
            <div className='container'>
            <MultiStepForm activeStep={active}>
                <Step label='Order Placed'>
                    <p className='text-center'>Your Order has been placed</p>
                </Step>
                <Step label='Process'>
                    <p className='text-center'>Your item Under Process</p>
                </Step>
                <Step label='Shipped'>
                    <p className='text-center'>Your item has been Shipped.</p>
                </Step>
                <Step label='Delivered'>
                    <p className='text-center'>Your item has been delivered</p>
                </Step>
            </MultiStepForm>
            
            {active === 0 && <h2 className='text-center' style={{color: "red"}}>Your Order Canceled.</h2>}
            <div className='row'>
                <div className='col-md-8'>
            {data?.products && data?.products.map((p,i) => {
                      return (
                        <div
                          className="row orderCart"
                          key={i}
                          style={{
                            border: "0.5px grey",
                            margin: 10,
                            // width: 700,
                            boxShadow: "1px 1px 10px 1px",
                            borderRadius: 5,
                          }}
                        >
                          <div className="col-md-4 d-flex" onClick={() => onSingleItemHandler(p?.product?.slug || p?.products?.slug)}>
                            <img
                              src={p?.product?.photo?.url || p?.products?.photo?.url}
                              className="card-img-top"
                              alt={p?.product?.name || p?.products?.name}
                              style={{
                                height: 180,
                                // width: 180,
                                margin: 10,
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div
                            className="col-md-5 text-start"
                            style={{ marginTop: 15 }}
                          >
                            <div onClick={() => onSingleItemHandler(p.product.slug)}>
                              <h3>{p?.product?.name || p?.products?.name}</h3> 
                              <p className='m-0'>{p?.product?.description.slice(0, 25) || p?.products?.description.slice(0, 25)}</p>
                              {/* <h4>₹{p.product.price}</h4> */}
                              {p?.product?.discount || p?.products?.discount ? (
                              <div style={{display: "flex", alignItems: "center", margin: 5}}>
                              <p className="card-text" style={{ fontSize: 20, marginBottom: 0 }}>₹{((p?.product?.price || p?.products?.price) - (p?.product?.discount || p?.products?.discount)).toFixed()} </p>
                              <p className="card-text discountPrice" style={{ fontSize: 15, marginBottom: 0 }}> ₹{p?.product?.price || p?.products?.price}</p>
                              <p className="card-text" style={{ fontSize: 16, color: 'red', marginBottom: 0 }}>{p?.product?.discountRate || p?.products?.discountRate}%</p>
                              </div>
                            ) : (
                              <p className="card-text" style={{ fontSize: 18, marginBottom: 0, margin: 5 }}>
                                ₹{p?.product?.price || p?.products?.price}
                            </p>
                            )}
                              <h5>Size : {p.size}</h5>
                              <h5>Qty : {p.quantity}</h5>
                              <h5 className='text-end'>Total Price : {p?.totalPrice}</h5>
                            </div>
                            </div>
                        
                        </div>
                      );
                    })}
                    </div>
                    <div className='col-md-4'>
                        <div className='row m-2' style={{border: "1px solid black"}}>
                            <h2 className='text-center mt-3'>Order Summary</h2>
                            <div className='col-6'>
                                <p>Name: </p>
                                <p>Total Product: </p>
                                <p>Total Price: </p>
                                <p>Payment: </p>
                                <p>Delivery Address: </p>
                            </div>
                            <div className='col-6 text-end'>
                                <p>{data?.buyer?.name}</p>
                                <p>{data?.products.length}</p>
                                <p>{getTotalAmount().toFixed(2)}</p>
                                <p>{data?.payment}</p>
                                <p>{data?.deliveryAddress}</p>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </Layout>
    </div>
  )
}

export default SingleOrderPage