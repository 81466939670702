import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { useAuth } from '../context/auth';
import moment from 'moment';

function OrderPage() {
    const [auth, setAuth] = useAuth();
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getUserOrders = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/auth/user-orders/${auth?.user?._id}`
      );
      console.log(result);
      setData(result?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getUserOrders();
    }
  }, []);

  const onSingleItemHandler = (slug) => {
    navigate(`/product/${slug}`);
  }

  const onSingleOrderHandler = (id) => {
    navigate(`/dashboard/orders/${id}`);
  }

  const getTotalAmount = (item) => {
    let amount = 0;
    const res = item && item.map((item) => {
      amount = amount + item.totalPrice;
      return amount;
    });
    return amount;
  };

  return (
    <Layout>
        <div className='container'>
        <h2 className='text-center m-3 mt-3'>Your Orders</h2>
          <div className="row">
            <div className="col-md-12">
              {data.map((item, i) => {
                return ( 
                  <div className='border' key={i}>
                    <table className="table orderTable" onClick={() => onSingleOrderHandler(item._id)}>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Buyer</th>
                          <th scope="col">Products</th>
                          <th scope="col">Payment</th>
                          <th scope="col">Status</th>
                          <th scope="col">Total Amount</th>
                          <th scope="col">Delivery Address</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{i + 1}</th>
                          <td>{item.buyer.name}</td> 
                          <td>{item.products.length}</td>
                          <td>{item.payment}</td>
                          <td style={{backgroundColor: item.status === "Cancel" ? "red" : "",borderRadius: 30, color: item.status === "Cancel" ? "white" : ""}}>{item.status}</td>
                          <td>{getTotalAmount(item.products).toFixed(2)}</td>
                          <td>{item.deliveryAddress}</td>
                          <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td> 
                        </tr>
                      </tbody>
                    </table>
                    {item.products.map((p,i) => {
                      return (
                        <div
                          className="row orderCart"
                          key={i}
                          style={{
                            border: "0.5px grey",
                            margin: 10,
                            boxShadow: "1px 1px 10px 1px",
                            borderRadius: 5,
                          }}
                        >
                          <div className="col-md-4 d-flex" onClick={() => onSingleItemHandler(p?.product?.slug || p?.products?.slug)}>
                            <img
                              src={p?.product?.photo?.url || p?.products?.photo?.url}
                              className="card-img-top"
                              alt={p?.product?.name || p?.products?.name}
                              style={{
                                height: 180,
                                // width: 180,
                                margin: 10,
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div
                            className="col-md-5 text-start"
                            style={{ marginTop: 15 }}
                          >
                            <div onClick={() => onSingleItemHandler(p?.product?.slug || p?.products?.slug)}>
                              <h3>{p?.product?.name}</h3> 
                              <p className='m-0'>{p?.product?.description.slice(0,25) || p?.products?.description.slice(0,25)}</p>
                              {/* <h4>₹{p.product.price}</h4> */}
                              {p?.product?.discount || p?.products?.discount ? (
                              <div style={{display: "flex", alignItems: "center", margin: 5}}>
                              <p className="card-text" style={{ fontSize: 20, marginBottom: 0 }}>₹{((p?.product?.price || p?.products?.price) - (p?.product?.discount || p?.products?.discount)).toFixed()} </p>
                              <p className="card-text discountPrice" style={{ fontSize: 15, marginBottom: 0 }}> ₹{p?.product?.price || p?.products?.price}</p>
                              <p className="card-text" style={{ fontSize: 16, color: 'red', marginBottom: 0 }}>{p?.product?.discountRate || p?.products?.discountRate}%</p>
                              </div>
                            ) : (
                              <p className="card-text" style={{ fontSize: 18, marginBottom: 0, margin: 5 }}>
                                ₹{p?.product?.price || p?.products?.price}
                            </p>
                            )}
                              <h5>Size : {p?.size}</h5>
                              <h5>Qty : {p?.quantity}</h5>
                              <h5 className='text-end'>Total Price : {p.totalPrice}</h5>
                            </div>
                            </div>
                        
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              
            </div>
          </div>
    </div>
    </Layout>
  )
}

export default OrderPage