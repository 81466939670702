import React from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import { useCart } from '../context/cart';
import { useAuth } from '../context/auth';
import moment from 'moment/moment';

function GenerateInvoice() {
    html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [612, 792]
      });
      pdf.internal.scaleFactor = 1;
      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('invoice-001.pdf');
    });
  } 

function Confirmation({deliveryAddress, order}) {

    const [cart, setCart] = useCart()
    const [auth, setAuth] = useAuth()
    console.log(cart)

    const getTotalAmount = () => {
        let amount = 0;
        const res = order && order.products.map((item) => {
          amount = amount + item.totalPrice;
          return amount;
        });
        return amount;
    };
  
  console.log("order===", order)
    
    return (
      <div className='container'>
        <div id="invoiceCapture" style={{fontSize: 16}}>
            <div>
              <div className="w-100">
                <h2 className="fw-bold my-2 text-center confirmHead">{'Fashion Avenue Shop'}</h2>
                {/* <p className="fw-bold text-end mb-1">
                  Invoice #: {'001'}
                </p> */}
              </div>
            </div>
            <div className="p-4">
              <div className="row mb-4 text-start confirmText">
                <div className='col-8'>
                  <div className="fw-bold text-start">Billed to:</div>
                  <div>Name: {auth?.user?.name}</div>
                  <div>Phone No.: {auth?.user?.phone}</div>
                  <div>E-mail: {auth?.user?.email}</div>
                  <div>Address: {deliveryAddress}</div>   
                  <div>Payment: {order?.payment}</div>   
                </div>
                {/* <div className='col-4'></div> */}
                <div className="col-4 text-end">
                  <div className="fw-bold mt-2 text-end">Date Of Issue:</div>
                  <div>{moment(Date()).format("DD MM YYYY")}</div>
                </div>
              </div>
              <table className="mb-0 table ConfirmTable">
                <thead>
                  <tr>
                    <th>QTY</th>
                    <th>DESCRIPTION</th>
                    <th className="text-end" style={{width: '100px'}}>SIZE</th>
                    <th className="text-end" style={{width: '100px'}}>PRICE</th>
                    <th className="text-end" style={{width: '100px'}}>DISCOUNT</th>
                    <th className="text-end" style={{width: '100px'}}>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.products.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{width: '100px'}}>
                          {item.quantity}
                        </td>
                        <td>
                          {item.product.name} - {item.product.description}
                        </td>
                        <td className="text-end" style={{width: '120px'}}>{item?.size}</td>
                        <td className="text-end" style={{width: '120px'}}>{item.product.price}</td>
                        <td className="text-end" style={{width: '120px'}}>{item.product.discount ? (item.product.discount * item.quantity).toFixed() : 0}</td>
                        <td className="text-end" style={{width: '120px'}}>{item.totalPrice.toFixed(2)}</td>
                      </tr>
                     );
                  })} 
                </tbody>
              </table>
              <table className='table'>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="text-end confirmText">
                    <td></td>
                    <td className="fw-bold" style={{width: '200px'}}>Total Amount </td>
                    <td className="text-end" style={{width: '100px'}}>{getTotalAmount().toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
                <div className="bg-light py-3 px-4 rounded">
                  Thank You for Shopping
                </div>
            </div>
          </div>
          <div className="pb-4 px-4">
            <div className='row'>
              <div className='col-3'>
              </div>
              <div className='col-6' style={{display: "flex", justifyContent: "center"}}>
                <button className="btn btn-success d-block mt-3 mt-md-0" onClick={GenerateInvoice}>
                  Download Invoice
                </button>
               </div>
               <div className='col-3'>
              </div>
            </div>
          </div>
      </div>
  )
}

export default Confirmation