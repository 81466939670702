import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Layout from '../components/layout/Layout'
import Loading from '../components/Loading'
import { useAuth } from '../context/auth'
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCart } from '../context/cart'


function Wishlist() {

    const [wishlist, setWishlist] = useState([])
    const [loading, setLoading] = useState(false)

    const [auth, setAuth] = useAuth()
    const [cart, setCart] = useCart();

    const navigate = useNavigate();

    const getUserWishlist = async() => {
        try {
          
          const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/wishlist/get-wishlist/${auth.user._id}`)
          console.log(res)
          let wishlistData = [];
          res.data.wishlist.map((item) => {
            wishlistData.push({
              id: item._id,
              product: item.product,
            })
            return wishlistData
          })
          console.log('wishlist',wishlistData)
          setWishlist(wishlistData)
        } catch (error) {
          console.log(error)
        }
      }

      useEffect(() => {
        getUserWishlist()
      },[auth])

      const onRemoveWishlist = async (id) => {
        try {
            console.log(id)
            const res = await axios.delete(`${process.env.REACT_APP_API}/api/v1/wishlist/delete-wishlist/${id}`)
            console.log(res)
            let myWishlist = [...wishlist];
            let index = myWishlist.findIndex((item) => item.id === id);
            myWishlist.splice(index, 1);
            setWishlist(myWishlist);
            if (res.data.success) {
              toast.success(res.data.message)
            }
          } catch (error) {
            console.log(error);
          }
      }
      
      const onAddToCartHandler = async (item) => {
        try {
          console.log(auth, item)
          if (!auth.token) {
            toast.error("Please login to Add to Cart.")
            return
          }
          const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/cart/create-cart`, { products: item._id, user: auth?.user?._id});
          console.log(res)
          if (res.data.success) {
            console.log(res.data.message)
            toast.success(res.data.message)
          }
          if (!res.data.success) {
            console.log(res.data.message)
            toast.error(res.data.message)
          }
          getUserCart()
        } catch (error) {
          console.log(error)
        }
      }
    
      const getUserCart = async() => {
        try {
          const data = localStorage.getItem("auth");
          const userData = JSON.parse(data);
          // console.log(userData)
          const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/cart/get-cart/${userData.user._id}`)
          console.log(res)
          let cartData = [];
          res.data.cart.map((item) => {
            const price = item?.products?.price - ( item?.products?.discount || 0)
            cartData.push({
              cid: item._id,
              products: item.products,
              quantity: item.quantity,
              totalPrice: price * item.quantity,
              allSize: item.allSize,
              size: item.size
            })
            return cartData
          })
          console.log(cartData)
          setCart(cartData)
        } catch (error) {
          console.log(error)
        }
      }

  return (
    <Layout>
        <section id="featured" className="my-5 pb-5">
                <div className="text-center mt-5 py-5 mr-5">
                    
                    <h3>Your Wishlist</h3>
                    <hr className="mx-auto hr" />
                </div>
                <div className="row mx-auto container-fluid">
                <div style={{marginLeft: 650}}>
                    {loading && <Loading />}
                </div>
                    {wishlist.length ? (
                        wishlist.map((p) => (
                            <div key={p.id} className="product text-center col-lg-3 col-md-6 col-12">
                                <div onClick={() => navigate(`/product/${p.product.slug}`)}>
                                <div className='imghoverCont'>
                                <img style={{ height: 300, width: 300 }} className="img-fluid mb-3 imghover" src={p.product.photo? p.product.photo.url : ""} alt="" />
                                </div>
                                <h5 className="p-name">{p.product.name}</h5>
                                <p className="p-name" style={{ color: 'gray' }}>{p.product.description}</p>
                                <h4 className="p-price">Rs. {p.product.price}</h4>
                                </div>
                                {/* <button className="buy-btn">Buy Now</button> */}
                                <button className="buy-btn m-2" style={{background: '#5dc2ea'}} onClick={() => onAddToCartHandler(p.product)}>Add to Cart</button>
                                <FavoriteIcon style={{color: "red"}} onClick={() => onRemoveWishlist(p.id)}/>
                            </div>
                        ))) : <p className='text-center'>No Product Found</p>}

                </div>
            </section>
    </Layout>
  )
}

export default Wishlist