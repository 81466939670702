export const Prices = [
    {
      _id: 0,
      name: "₹0 to 99",
      array: [0, 99],
    },
    {
      _id: 1,
      name: "₹100 to 299",
      array: [100, 299],
    },
    {
      _id: 2,
      name: "₹300 to 699",
      array: [300, 699],
    },
    {
      _id: 3,
      name: "₹700 to 999",
      array: [700, 999],
    },
    {
      _id: 4,
      name: "₹1000 to 1499",
      array: [1000, 1499],
    },
    {
      _id: 4,
      name: "₹1500 or more",
      array: [1500, 9999999],
    },
  ];
  