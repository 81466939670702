

import './App.css';
import Layout from './components/layout/Layout'
import { Route, Routes } from 'react-router-dom'
import Homepage from './pages/Homepage'
import About from './pages/About'
import Contact from './pages/Contact'
import PageNotFound from './pages/PageNotFound'
import Register from './pages/Register'
import Login from './pages/Login'
import ForgotPassword from "./pages/ForgotPassword";
import PrivateRoute from './components/Routes/Private'
import Dashboard from './pages/user/Dashboard'
import ProductDetails from './pages/ProductDetails'
import Shop from './pages/Shop'
import SearchPage from './pages/SearchPage'
import CartPage from './pages/CartPage'
import Wishlist from './pages/Wishlist'
import Profile from './pages/Profile'
import BuyNow from './pages/BuyNow'
import OrderPage from './pages/OrderPage'
import SingleOrderPage from './pages/SingleOrderPage'
import CategoryPage from './pages/CategoryPage'
import OfferPage from './pages/OfferPage';

function App() {
  
  return (
    // <div>
    //   <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 fixed-top">
    //     <div className="container">
    //       <img src={logo} alt=""/>
    //       <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //         <span id="bar" className="navbar-toggler-icon"></span>
    //       </button>
    //       <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //         <ul className="navbar-nav ml-auto">
    //           <li className="nav-item">
    //             <a className="nav-link active" href="#">Home</a>
    //           </li>
    //           <li className="nav-item">
    //             <a className="nav-link" href="shop.html">Shop</a>
    //           </li>
    //           <li className="nav-item">
    //             <a className="nav-link" href="#">Blog</a>
    //           </li>
    //           <li className="nav-item">
    //             <a className="nav-link" href="#">About</a>
    //           </li>
    //           <li className="nav-item">
    //             <a className="nav-link" href="#">Contact Us</a>
    //           </li>
    //           <li className="nav-itemi">
    //             <i className="fas fa-search"></i>
    //             {/* <i className="fas fa-shopping-bag"></i> */}
    //           </li>
    //           <li className="nav-itemi">
    //             {/* <i className="fas fa-search"></i> */}
    //             <i className="fas fa-shopping-bag"></i>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </nav>

    //   <section id="home">
    //     <div className="container">
    //         <h5>NEW ARRAIVALS</h5>
    //         <h1><span>Best Price</span> This Year</h1>
    //         <p>Shoomatic offers Your very comfortable time <br/> on walking and exercises.</p>
    //         <button>Shop Now</button>
    //     </div>
    //   </section>

    //   <section id="brand" className="container">
    //     <div className="row m-0 py-5">
    //         <img className="img-fluid col-lg-2 col-md-4 col-6" src={brand1} alt=""/>
    //         <img className="img-fluid col-lg-2 col-md-4 col-6" src={brand2} alt=""/>
    //         <img className="img-fluid col-lg-2 col-md-4 col-6" src={brand3} alt=""/>
    //         <img className="img-fluid col-lg-2 col-md-4 col-6" src={brand4} alt=""/>
    //         <img className="img-fluid col-lg-2 col-md-4 col-6" src={brand5} alt=""/>
    //         <img className="img-fluid col-lg-2 col-md-4 col-6" src={brand6} alt=""/>
    //     </div>
    //   </section>

    //   <section id="new" className="w-100">
    //     <div className="row p-0 m-0">
    //         <div className="one col-lg-4 col-md-12 col-12 p-0">
    //             <img className="img-fluid" src={new1} alt=""/>
    //             <div className="details">
    //                 <h2>Extreme Rare Sneakers</h2>
    //                 <button className="text-uppercase">Shop Now</button>
    //             </div>
    //         </div>
    //         <div className="one col-lg-4 col-md-12 col-12 p-0">
    //             <img className="img-fluid" src={new2} alt=""/>
    //             <div className="details">
    //                 <h2>Awesome Blank Outfit</h2>
    //                 <button className="text-uppercase">Shop Now</button>
    //             </div>
    //         </div>
    //         <div className="one col-lg-4 col-md-12 col-12 p-0">
    //             <img className="img-fluid" src={new3} alt=""/>
    //             <div className="details">
    //                 <h2>Sportwear Up To 50% Off</h2>
    //                 <button className="text-uppercase">Shop Now</button>
    //             </div>
    //         </div>
    //     </div>
    //   </section>

      // <section id="featured" className="my-5 pb-5">
      //   <div className="container text-center mt-5 py-5">
      //       <h3>Our featured</h3>
      //       <hr className="mx-auto"/>
      //       <p>Here you can check out our new products with fair price on rymo.</p>
      //   </div>
      //   <div className="row mx-auto container-fluid">
      //       <div className="product text-center col-lg-3 col-md-6 col-12">
      //           <img className="img-fluid mb-3" src={featured1} alt=""/>
      //           <div className="star rating">
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //           </div>
      //           <h5 className="p-name">Sport Boots</h5>
      //           <h4 className="p-price">Rs.599.00</h4>
      //           <button className="buy-btn">Buy Now</button>
      //       </div>
      //       <div className="product text-center col-lg-3 col-md-6 col-12">
      //           <img className="img-fluid mb-3" src={featured2} alt=""/>
      //           <div className="star">
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //           </div>
      //           <h5 className="p-name">Casual Boots</h5>
      //           <h4 className="p-price">Rs.699.00</h4>
      //           <button className="buy-btn">Buy Now</button>
      //       </div>
      //       <div className="product text-center col-lg-3 col-md-6 col-12">
      //           <img className="img-fluid mb-3" src={featured3} alt=""/>
      //           <div className="star">
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //           </div>
      //           <h5 className="p-name">Model Bag</h5>
      //           <h4 className="p-price">Rs.1299.00</h4>
      //           <button className="buy-btn">Buy Now</button>
      //       </div>
      //       <div className="product text-center col-lg-3 col-md-6 col-12">
      //           <img className="img-fluid mb-3" src={featured4} alt=""/>
      //           <div className="star">
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //               <i className="fas fa-star"></i>
      //           </div>
      //           <h5 className="p-name">Model Cap</h5>
      //           <h4 className="p-price">Rs.399.00</h4>
      //           <button className="buy-btn">Buy Now</button>
      //       </div>
      //   </div>
      // </section>

    //   <section id="banner" className="my-5 py-5">
    //     <div className="container">
    //       <h4>MID SEASON'S SALE</h4>
    //       <h1>Autumn Collection <br/> UP TO 20% OFF</h1>
    //       <button className="text-uppercase">Shop Now</button>
    //     </div>
    //   </section>

    //   <section id="clothes" className="my-5"> 
    //     <div className="container text-center mt-5 py-5">
    //         <h3>Dresses & Jumpsuits</h3>
    //         <hr className="mx-auto"/>
    //         <p>Here you can check out our new products with fair price on rymo.</p>
    //     </div>
    //     <div className="row mx-auto container-fluid">

    //       {feat.map(item => {
    //         return (
    //           <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src={item.img} alt=""/>
    //             <div className="star rating">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">{item.name}</h5>
    //             <h4 className="p-price">Rs.520.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         )
    //       })}
    //         {/* <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/clothes/1.jpg" alt=""/>
    //             <div className="star rating">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Dress</h5>
    //             <h4 className="p-price">Rs.520.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/clothes/2.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Dress</h5>
    //             <h4 className="p-price">Rs.966.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/clothes/3.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">T-Shirt</h5>
    //             <h4 className="p-price">Rs.1299.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/clothes/1.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Dress</h5>
    //             <h4 className="p-price">Rs.999.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div> */}
    //     </div>
    //   </section>

    //   <section id="watches" className="my-5">
    //     <div className="container text-center mt-5 py-5">
    //         <h3>Best Watches</h3>
    //         <hr className="mx-auto"/>
    //         <p>Here you can check out our new products with fair price on rymo.</p>
    //     </div>
    //     <div className="row mx-auto container-fluid">
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/watches/1.jpg" alt=""/>
    //             <div className="star rating">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Watch</h5>
    //             <h4 className="p-price">Rs.1599.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/watches/2.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Watch</h5>
    //             <h4 className="p-price">Rs.699.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/watches/3.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Watch</h5>
    //             <h4 className="p-price">Rs.999.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/watches/4.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Watch</h5>
    //             <h4 className="p-price">Rs.1199.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //     </div>
    //   </section>

    //   <section id="shoes" className="my-5 pb-5">
    //     <div className="container text-center mt-5 py-5">
    //         <h3>Running Shoes</h3>
    //         <hr className="mx-auto"/>
    //         <p>Here you can check out our new products with fair price on rymo.</p>
    //     </div>
    //     <div className="row mx-auto container-fluid">
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/shoes/8.jpg" alt=""/>
    //             <div className="star rating">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Shoes</h5>
    //             <h4 className="p-price">Rs.1599.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/shoes/7.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Shoes</h5>
    //             <h4 className="p-price">Rs.699.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/shoes/6.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Shoes</h5>
    //             <h4 className="p-price">Rs.999.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //         <div className="product text-center col-lg-3 col-md-6 col-12">
    //             <img className="img-fluid mb-3" src="img/shoes/5.jpg" alt=""/>
    //             <div className="star">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //             </div>
    //             <h5 className="p-name">Shoes</h5>
    //             <h4 className="p-price">Rs.1199.00</h4>
    //             <button className="buy-btn">Buy Now</button>
    //         </div>
    //     </div>
    //   </section>

    //   <footer className="mt-5 py-5">
    //       <div className="row container mx-auto pt-5">
    //         <div className="footer-one col-lg-4 col-md-6 col-12 mb-3">
    //           <img src="img/logo2.png" alt=""/>
    //           <p className="pt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis quos optio ipsum rerum. Dignissimos, impedit.</p>
    //         </div>
    //         <div className="footer-one col-lg-4 col-md-6 col-12 mb-3">
    //           <h5 className="pb-2">Featured</h5>
    //           <ul className="text-uppercase list-unstyled">
    //             <li><a href="#">men</a></li>
    //             <li><a href="#">women</a></li>
    //             <li><a href="#">boys</a></li>
    //             <li><a href="#">girls</a></li>
    //             <li><a href="#">shoes</a></li>
    //             <li><a href="#">clothes</a></li>
    //             <li><a href="#">new arrivals</a></li>
    //           </ul>
    //         </div>
    //         <div className="footer-one col-lg-4 col-md-6 col-12 mb-3">
    //           <h5 className="pb-2">Contact Us</h5>
    //           <div>
    //             <h6 className="text-uppercase">Address</h6>
    //             <p>76, Sahajananda soc., Surat, India</p>
    //           </div>
    //           <div>
    //             <h6 className="text-uppercase">Phone</h6>
    //             <p>02616985325</p>
    //           </div>
    //           <div>
    //             <h6 className="text-uppercase">Email</h6>
    //             <p>jatin@gmail.com</p>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="copyright mt-5">
    //         <div className="row container mx-auto">
    //           <div className="col-lg-4 col-md-6 col-12 mb-4">
    //             <img src="img/payment.png" alt=""/>
    //           </div>
    //           <div className="col-lg-4 col-md-6 col-12 text-nowrap mb-3">
    //             <p>rymo &#9400; eCommerce 2021. All Rights Reserved</p>
    //           </div>
    //           <div className="col-lg-4 col-md-6 col-12">
    //             <a href="#"><i className="fab fa-facebook"></i></a>
    //             <a href="#"><i className="fab fa-twitter"></i></a>
    //             <a href="#"><i className="fab fa-linkedin-in"></i></a>
    //           </div>
    //         </div>
    //       </div>
    //   </footer>
    // </div>

    <div>
      <Routes>
        <Route exact path='/' element={<Homepage />}/>
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/register' element={<Register />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/shop' element={<Shop />} />
        <Route exact path='/cart' element={<CartPage />} />
        <Route exact path='/search' element={<SearchPage />} />
        <Route exact path='/product/:slug' element={<ProductDetails />} />
        <Route exact path='/forgot-password' element={<ForgotPassword />} />
        <Route exact path='/wishlist' element={<Wishlist />} />
        <Route exact path='/profile' element={<Profile />} />
        <Route exact path='/offer/:id' element={<OfferPage />} />
        <Route path='/category/:id' element={<CategoryPage />} />
        <Route path='/*' element={<PageNotFound />} />

        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<Dashboard />} />
          <Route path="buynow" element={<BuyNow />} />
          <Route path="orders" element={<OrderPage />} />
          <Route path="orders/:id" element={<SingleOrderPage />} />
          {/* <Route path="user/orders" element={<Orders />} />
          <Route path="user/profile" element={<Profile />} /> */}
        </Route>
        
      </Routes>
    </div>
  );
}

export default App;
