import { useState, createContext } from "react";

const OrderContext = createContext();
const OrderProvider = ({ children }) => {
    const [oldAddress, setOldAddress] = useState("")
    const [newAddress, setNewAddress] = useState("")
    const [deliveryAdd, setDeliveryAdd] = useState("old")
    const [paymentOption, setPaymentOption] = useState()

  return (
    <OrderContext.Provider value={{paymentOption, setPaymentOption, oldAddress, setOldAddress, newAddress, setNewAddress, deliveryAdd, setDeliveryAdd }}>
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
