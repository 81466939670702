import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import toast from "react-hot-toast";
import { Badge } from '@mui/material';
import { useCart } from '../../context/cart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import axios from 'axios';
import logo from "../../assets/logo.png"

function Header() {

  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart()
  const [category, setCategory] = useState([]);

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    setCart([]);
  };

  const getCategory = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/category/get-category`
    );
    // console.log(res.data.category)
    let dataT = [];
    dataT = res.data.category.map((item) => {
      return {
        id: item._id,
        name: item.name,
        slug: item.slug,
      };
    });
    setCategory(dataT);
  };

  useEffect(() => {
    getCategory()
  },[])

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 fixed-top">
        <div className="container">
          {/* <img src={logo} alt="" height={80} width={190} /> */}
          <NavLink className="navbar-brand fw-bold fs-4" to="/">
            Fashion Avenue 
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span id="bar" className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/shop">Shop</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">About</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">Contact Us</NavLink>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Category
                </Link>
                <ul className="dropdown-menu">
                  <>
                    {category.map((item) => {
                      return (
                        <li key={item.id}>
                          <NavLink className="dropdown-item" to={`/category/${item.id}`}>
                            {item.name}
                          </NavLink>
                        </li>
                      )
                    })}
                      
                    </>
                </ul>
              </li>
              {/* <li className="nav-itemi">
                <i className="fas fa-search"></i>
                <i className="fas fa-shopping-bag"></i>
              </li> */}
              {/* <li className="nav-itemi">
                <i className="fas fa-search"></i>
                <i className="fas fa-shopping-bag"></i>
              </li> */}
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/login">login</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/register">Register</NavLink>
              </li> */}
            </ul>
            <ul className='navbar-nav ms-auto'>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle mt-1"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  style={{ border: "none" }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg>
                </Link>
                <ul className="dropdown-menu">
                  {!auth.user ? (
                    <div className='buttons'>
                      <li>
                        <NavLink
                          // to={`/dashboard/${
                          //   auth?.user?.role === 1 ? "admin" : "user"
                          // }`}
                          to="/register"
                          className="dropdown-item"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                      </svg> Register
                          
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          // onClick={handleLogout}
                          to="/login"
                          className="dropdown-item"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                        <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                      </svg> login
                          
                        </NavLink>
                      </li>
                    </div>
                  ) : (
                    <>
                    <li>
                      <NavLink
                        to="/profile"
                        className="dropdown-item"
                      ><PersonIcon /> Profile
                        
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dashboard/orders"
                        className="dropdown-item"
                      ><ShoppingBagIcon /> Orders
                        
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/wishlist"
                        className="dropdown-item"
                      ><FavoriteIcon style={{color: "red"}}/> Wishlist
                        
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={handleLogout}
                        to="/login"
                        className="dropdown-item"
                      ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
                      <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
                    </svg> Logout
                        
                      </NavLink>
                    </li>
                    </>
                  )}

                </ul>
              </li>
              <li className="nav-item">
                
              <NavLink className="nav-link btn p-1 px-2" to="/cart">
              <Badge badgeContent={cart.length} color="primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart4" viewBox="0 0 16 16">
                  <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                </svg>
              </Badge>
              </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header