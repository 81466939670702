import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from '../context/auth'
import EditIcon from '@mui/icons-material/Edit';
import { OrderContext } from '../context/orderContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { toast } from 'react-toastify';

function Address({active, setActive}) {
    const [change, setChange] = useState(false)
    const [addAddress, setAddAddress] = useState(false)
    const { oldAddress, setOldAddress,
         newAddress, setNewAddress,
          deliveryAdd, setDeliveryAdd} = useContext(OrderContext)
    const [auth, setAuth] = useAuth()
    console.log(deliveryAdd)

    useEffect(() => {
        setOldAddress(auth?.user?.address)
    }, [])

    const deliveryAddress = deliveryAdd === "old" ? oldAddress : newAddress;
       console.log("deliveryAddress", deliveryAddress)

    const style = {
        width: "100%",
        padding: "5px 5px",
        border: "2px solid #ddd",
        borderRadius: "5px",
        fontSize: "1rem",
        outline: "none",
        marginBottom: "1rem",
        boxSizing: "border-box",
    }

    const onNextHandler =() => {
        if (!deliveryAddress) {
            toast.error("Please Select or Enter Delivery Address.")
            return
        }
        const regex = /^[0-9]+\s*,\s*\w+\s+\w+\s*,\s*\w+\s*,\s*\w+\s+\w+\s*-\s*\d{6}$/;
        const isValidAddress = regex.test(deliveryAddress);
        console.log('regex', isValidAddress)
        if (!isValidAddress) {
            toast.error("Please Enter Valid Delivery Address.(like: 123, Laxmi Soc, Godadara, Surat Gujarat-452012)")
            return
        }
        setActive(active + 1)
    }

    return (<>
        <div className='addressInput' style={{ display: "flex", flexDirection: "column", marginTop: 50 }}>
            <h4>Delivery Address</h4>
            <div className='d-flex m-1'>
            <p className='m-0'>{auth?.user?.name}</p> <EditIcon onClick={() => setChange(!change)}/>
            </div>
            <textarea type="text" 
                value={oldAddress} 
                onChange={(e) => setOldAddress(e.target.value)} 
                disabled={!change} style={{ ...style }} 
                placeholder="Enter Address" label="Address" />

            <button className='btn btn-warning' style={{width: 120, fontSize: 12}}
                onClick={() => setAddAddress(true)}>Add Address</button>

            {addAddress && <textarea type="text" 
                value={newAddress} 
                onChange={(e) => setNewAddress(e.target.value)} 
                style={{ ...style, marginTop: 10 }} 
                placeholder="Enter Address" label="Address" />}

                {addAddress && <div>
                    <label>Select Delivery Address</label>
                    <div className="form-check">
                    <input className="form-check-input" onChange={(e) => setDeliveryAdd(e.target.value)} type="radio" name="exampleRadios" id="exampleRadios1" value="old"/>
                    <label className="form-check-label" htmlFor="exampleRadios1">
                        Old Address
                    </label>
                    </div>
                    <div className="form-check">
                    <input className="form-check-input" onChange={(e) => setDeliveryAdd(e.target.value)} type="radio" name="exampleRadios" id="exampleRadios2" value="new"/>
                    <label className="form-check-label" htmlFor="exampleRadios2">
                        New Address
                    </label>
                    </div>
                </div>}
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
        <button style={{ margin: 20, borderRadius: 10, padding: 10 }} onClick={() => setActive(active - 1)}> <ArrowBackIcon /> Previous</button>
        <button
        onClick={onNextHandler}
        style={{ margin: 20, borderRadius: 10, padding: 10 }}
      >
        Next <ArrowForwardIcon />
      </button>
      </div>
      </>
    )
}

export default Address