import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import Layout from '../components/layout/Layout';
import '../styles/login.css'
// import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import person from '../assets/img/person.png'


function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [auth, setAuth] = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!email || !password) {
                toast.error("Please fill Details");
                return
            }
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/login`, {
            email,
            password,
          });
          console.log(res)
          if (res.data.success) {
            console.log(res.data)
            toast.success(res.data.message);
            setAuth({
              ...auth,
              user: res.data.user,
              token: res.data.token,
            });
            localStorage.setItem("auth", JSON.stringify(res.data));
            navigate("/");
          } else {
            toast.error(res.data.message);
          }
        } catch (error) {
          console.log(error);
          toast.error(error.response.data.message);
        }
      };

    return (
        <Layout title="Fashion Avenue - Login">
            <form>
            <div className='main' style={{color: 'white'}}>
                <div className='sub-main'>
                    <div>
                        <div>
                            <h1 className='LHeader m-5'>Login</h1>
                            <div className='mt-5'>
                                {/* <img alt="emial" className='email' /> */}
                                <input type="email" placeholder='Enter Email-id' className='fill input' onChange={(event) => setEmail(event.target.value) }/>
                            </div>
                            <div className='second-input'>
                                {/* <img alt='password' className='email' /> */}
                                <input type="password" placeholder='Enter Password' className='fill input' onChange={(event) => setPassword(event.target.value) }/>
                            </div>
                            <div className='reg-link'>
                                <Link className='link forgot-password' to='/forgot-password'>
                                    <li>Forgot Password</li>
                                </Link>
                            </div>
                            <div className='login-btn'>
                                    <button type="button" onClick={handleSubmit}>Login</button>
                            </div>
                            <div className='reg-link'>
                                <p>Don't have an Account ?</p>
                                <Link className='link forgot-password' to='/register'>
                                    <li>Register Now</li>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </Layout>
  )
}

export default Login