import React from 'react'

function Footer() {
  return (
    <div>
        <footer className="mt-0 py-1">
          <div className="row container mx-auto pt-2">
            <div className="footer-one col-lg-4 col-md-6 col-12 mb-1">
              <img src="img/logo2.png" alt=""/>
              <p className="pt-3">Shoomatic offers Your very comfortable time on walking and exercises.</p>
            </div>
            <div className="footer-one col-lg-4 col-md-6 col-12 mb-1">
              <h5 className="pb-2">Featured</h5>
              <ul className="text-uppercase list-unstyled">
                <li><a href="/">men</a></li>
                <li><a href="/">women</a></li>
                <li><a href="/">boys</a></li>
                <li><a href="/">girls</a></li>
                <li><a href="/">shoes</a></li>
                <li><a href="/">clothes</a></li>
                <li><a href="/">new arrivals</a></li>
              </ul>
            </div>
            <div className="footer-one col-lg-4 col-md-6 col-12 mb-1">
              <h5 className="pb-2">Contact Us</h5>
              <div>
                <h6 className="text-uppercase">Address</h6>
                <p>76, Sahajananda soc., Surat, India</p>
              </div>
              <div>
                <h6 className="text-uppercase">Phone</h6>
                <p>02616985325</p>
              </div>
              <div>
                <h6 className="text-uppercase">Email</h6>
                <p>fashionavenue.j2a@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="copyright mt-1">
            <div className="row container mx-auto">
              <div className="col-lg-4 col-md-6 col-12 mb-4">
                <img src="img/payment.png" alt=""/>
              </div>
              <div className="col-lg-4 col-md-6 col-12 text-wrap mb-3">
                <p>fashionavenue &#9400; eCommerce 2021. All Rights Reserved</p>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <a href="/"><i className="fab fa-facebook"></i></a>
                <a href="/"><i className="fab fa-twitter"></i></a>
                <a href="/"><i className="fab fa-linkedin-in"></i></a>
              </div>
            </div>
          </div>
      </footer>
    </div>
  )
}

export default Footer