import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import axios from "axios";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

function ReviewModel({ handleClose, open, products, rewEdit}) {
  const [value, setValue] = useState(0);
  const [desc, setDesc] = useState("");
  const [product, setProduct] = useState("");
  const [oldStar, setOldStar] = useState("");
  // console.log(rewEdit)

  const getUserReview = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/review/single-review/${rewEdit}`)
      console.log('single review=====', res.data.reviews)
      setValue(res.data.reviews.star)
      setDesc(res.data.reviews.desc)
      setOldStar(res.data.reviews.star)
      setProduct(res.data.reviews.products)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    rewEdit && getUserReview()
  },[rewEdit])
    
    const onAddReviewHandler = async() => {
        try {
            if (!value) {
                return toast.error("Please fill Rating.");
            }
            if (!desc) {
                return toast.error("Please fill Description.");
            }
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/review/create-review`,{
                products: products,
                star: value,
                desc
            })
            console.log(res)
            if (res?.status === 201) {
                toast.success(res.data.message)
            }
            if (res?.status === 202) {
                toast.error(res.data.message)
            }
            handleClose()
            setValue(0)
            setDesc("")
        } catch (error) {
            console.log(error)
            handleClose()
        }
    }
  
  const onUpdateReviewHandler = async() => {
    try {
      if (!value) {
        return toast.error("Please fill Rating.");
      }
      if (!desc) {
          return toast.error("Please fill Description.");
      }
      const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/review/update-review/${rewEdit}`,{
          products: product,
          star: value,
          desc,
          oldStar
      })
      console.log(res)
      if (res?.data?.success) {
        toast.success(res?.data?.message)
      }
      handleClose()
      setDesc("")
      setValue(0)
      setProduct("")
      setOldStar(0)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5>{rewEdit ? "Edit" : "Add"} Review</h5>
          <Rating
            name="half-rating"
            defaultValue={0}
            value={value}
            precision={0.5}
            size="large"
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />

          <textarea
            type="text"
            placeholder="Enter Review"
            className="form-control mt-2"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          {
            !rewEdit ? (
              <button type="button" className="btn btn-primary mt-3" onClick={onAddReviewHandler}>
                Submit
              </button>
            ) : (
              <button type="button" className="btn btn-primary mt-3" onClick={(onUpdateReviewHandler)}>
                Edit
              </button>
            )
              
          }
          
        </Box>
      </Modal>
    </div>
  );
}

export default ReviewModel;
