import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from './Loading'

function Offers() {

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const getOffers = async() => {
        try {
            setLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/offer/get-offer`)
            console.log('res: ', res);
            setData(res?.data?.offers)
            setLoading(false)
        } catch (error) {
            console.log('error: ', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getOffers()
    }, [])
    
    const onSingleOfferHandler = (id) => {
        console.log('id: ', id);
        navigate(`/offer/${id}`)
    }

  return (
    <div className='container mb-3'>
    <h2 className='text-center'>Our Best Offers</h2>
    <hr style={{width: 200, margin: "auto", marginTop: 10, marginBottom: 20}} className="text-center"/>
          {loading ? <Loading /> : <div className='row' style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              {data && data.map((item, i) => {
                  return (
                    <div className='col-md-4' key={i}>
                        <div className='tempContainer offerContainer' onClick={() => onSingleOfferHandler(item._id)}>
                            <img src={item?.photo?.url} alt='offer images'
                                style={{height: 250, borderRadius: 5, opacity: 0.7}} className="offerContainer"/>
                            <h2 className='tempText'>{item.couponCodeName}</h2>
                        </div>
                    </div>
                  )
              })}
        </div>}
    </div>
  )
}

export default Offers