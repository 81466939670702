import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CartItem from "../components/CartItem";
import Layout from "../components/layout/Layout";
import useCategory from "../hooks/useCategory";

function CategoryPage() {
  const [category, setCategory] = useState();
  const categories = useCategory();
  console.log("categories: ", categories);
  console.log("category: ", category);

    const { id } = useParams();
    
    const navigate = useNavigate()

  const FilterProduct = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/product/product-category/${id}`,
      );
      console.log("filter--------", result);
      setCategory(result?.data?.products);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    FilterProduct();
  }, [id]);

  return (
    <Layout>
      <div className="text-center m-5">
        <h3 style={{marginTop: 90}}>Products by Category</h3>
        <hr className="mx-auto hr" />
        {categories.map((item, i) => {
          return (
            <Fragment key={i}>
              <input
                type="radio"
                className="btn-check m-2"
                value={item._id}
                name="options-outlined"
                checked={item._id === id}
                id={`success-outlined ${i}`}
                onChange={(e) => navigate(`/category/${e.target.value}`)}
              />
              <label
                className="btn btn-outline-danger m-1"
                htmlFor={`success-outlined ${i}`}
              >
                {item.name}
              </label>
            </Fragment>
          );
        })}
        <div className="row mt-5">
          <div
            className="col-md-12"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {category && <CartItem products={category} />}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CategoryPage;
