import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CartItem from '../components/CartItem';
import Layout from '../components/layout/Layout'
import { useAuth } from '../context/auth';
import { useCart } from '../context/cart';
import { useSearch } from '../context/search';

function SearchPage() {

    const [values, setValues] = useSearch();

    
  return (
    <Layout>
        <section id="featured" className="my-5 pb-5">
                <div className="text-center mt-5 py-5">
                <h3>Search Products</h3>
                <hr className="mx-auto hr" />
                <div className="row mx-auto container-fluid">
                    {/* {values.results.length ? (
                        values.results.map((p) => (
                            <div key={p._id} className="product text-center col-lg-3 col-md-6 col-12">
                                <div onClick={() => navigate(`/product/${p.slug}`)}>
                                <img style={{ height: 300, width: 300 }} className="img-fluid mb-3" src={`${process.env.REACT_APP_API}/api/v1/product/product-photo/${p._id}`} alt="" />
                                <h5 className="p-name">{p.name}</h5>
                                <p className="p-name" style={{ color: 'gray' }}>{p.description}</p>
                                <h4 className="p-price">Rs. {p.price}</h4>
                                </div>
                                <button className="buy-btn">Buy Now</button>
                                <button className="buy-btn m-2" style={{background: 'skyblue'}} onClick={() => onAddToCartHandler(p)}>Add to Cart</button>
              
                            </div>
                        ))) : <p className='text-center'>No Product Found</p>} */}
                        <CartItem products={values.results}/>
                    </div>
                </div>
            </section>
    </Layout>
  )
}

export default SearchPage