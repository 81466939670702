import React, { useContext } from 'react'
import { OrderContext } from '../context/orderContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Payment({active, setActive, onOrderHandler}) {

    const { paymentOption, setPaymentOption } = useContext(OrderContext)
    return (
        <div>
            <div>
                <label>Select Payment Method</label>
                <div className="form-check">
                    <input className="form-check-input" onChange={(e) => setPaymentOption(e.target.value)} type="radio" name="exampleRadios" id="exampleRadios1"
                     value="cashOnDelivery" />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                        Cash On Delivery
                    </label>
                </div>
                {/* <div className="form-check">
                    <input className="form-check-input" onChange={(e) => setPaymentOption(e.target.value)} type="radio" name="exampleRadios" id="exampleRadios2"
                     value="paypal" />
                    <label className="form-check-label" htmlFor="exampleRadios2">
                        Paypal
                    </label>
                </div> */}
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <button style={{ margin: 20, borderRadius: 10, padding: 10 }} onClick={() => setActive(active - 1)}> <ArrowBackIcon /> Previous</button>
                <button
                    className='btn btn-warning'
                    onClick={onOrderHandler}
                    style={{ margin: 20, borderRadius: 10, padding: 10 }}
                    >
                    Order Now
                    </button>
            </div>
        </div>
    )
}

export default Payment