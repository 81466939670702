import React from 'react'
import Layout from '../components/layout/Layout'
import aboutImg from "../assets/a6.jpg"

function About() {
  return (
    <Layout title="about">

      <section class="page-header about-header">
        <h2 style={{ color: 'white' }}>#KnowUs</h2>
        <p style={{ color: 'white' }}>We offer a wide range of products,
            carefully curated to cater to your diverse needs and interests.</p>
      </section>
      <section class="section-p1 about-head">
        <img src={aboutImg} alt="" />
        <div>
          <h2>Who We Are?</h2>
          <p>
            Welcome to our e-commerce website! We are a team of dedicated
            professionals passionate about bringing you the best online shopping
            experience. we have created an intuitive and user-friendly
            platform for all your online shopping needs.
          </p>
          <p>
            Our mission is to provide you with a seamless and enjoyable shopping
            experience from start to finish. We offer a wide range of products,
            carefully curated to cater to your diverse needs and interests. From
            fashion and accessories to home essentials and electronics, we have
            something for everyone. Our product catalog is regularly updated to
            bring you the latest trends and innovations in the market.
          </p>
          <p>
            At our core, we prioritize customer satisfaction. We strive to offer
            you excellent customer service, ensuring that your questions, concerns,
            and feedback are addressed promptly and professionally. Our team is
            always ready to assist you in finding the perfect product, navigating
            the website, and resolving any issues that may arise during your
            shopping journey.
          </p>
          <p>
            We are committed to protecting your privacy and maintaining the security
            of your personal information. Our website uses state-of-the-art
            encryption technology to safeguard your data, and we do not share your
            information with third parties without your explicit consent. You can
            shop with confidence, knowing that your privacy is our top priority.
          </p>
          <p>
            We are constantly working on improving our website and adding new
            features to enhance your shopping experience. We value your feedback and
            suggestions, and we are committed to continuously evolving and growing
            to meet your needs.
          </p>
          <p>
            Thank you for choosing our e-commerce website for your online shopping
            needs. We look forward to serving you and providing you with an
            unforgettable shopping experience. Happy shopping!
          </p>
        </div>
      </section>

    </Layout>
  )
}

export default About