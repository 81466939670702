import axios from 'axios';
import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useAuth } from '../context/auth';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';


function WishlistC({isliked, setWishlist, removeId, item}) {

    const [auth, setAuth] = useAuth()

    const getUserWishlist = async() => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/wishlist/get-wishlist/${auth.user._id}`)
            // console.log('wishlist',res)
            let wishlistData = [];
            res.data.wishlist.map((item) => {
              wishlistData.push({
                id: item._id,
                product: item.product,
              })
              return wishlistData
            })
            // console.log(wishlistData)
            setWishlist(wishlistData)
        } catch (error) {
          console.log(error)
        }
      }
    
      const onAddWishlistHandler = async(item) => {
        try {
          console.log(auth, item)
          if (!auth.token) {
            toast.error("Please login to Add to Wishlist.")
            return
          }
          const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/wishlist/create-wishlist`, { product: item._id, user: auth?.user?._id});
          console.log(res)
          if (res.data.success) {
            console.log(res.data.message)
            toast.success(res.data.message)
          }
          if (!res.data.success) {
            console.log(res.data.message)
            toast.error(res.data.message)
          }
          getUserWishlist()
        } catch (error) {
          console.log(error)
        }
      }
    
      const onRemoveWishlist = async (id) => {
        try {
            console.log(id)
            const res = await axios.delete(`${process.env.REACT_APP_API}/api/v1/wishlist/delete-wishlist/${id}`)
            console.log(res)
            getUserWishlist();
            if (res.data.success) {
              toast.success(res.data.message)
            }
          } catch (error) {
            console.log(error);
          }
      }

      useEffect(() => {
        getUserWishlist()
      },[])

  return (
    <>
        {isliked.length ? 
              ( <FavoriteIcon style={{color: 'red'}} onClick={() => onRemoveWishlist(removeId)}/> ) : (
                <FavoriteBorderIcon onClick={() => onAddWishlistHandler(item)}/>
              )}
    </>
  )
}

export default WishlistC