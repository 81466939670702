import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import Layout from '../components/layout/Layout';
import '../styles/login.css'
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';


function ForgotPassword() {
    const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [answer, setAnswer] = useState("");

  const navigate = useNavigate();

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        if (!email || !newPassword || !answer) {
            toast.error("Please fill Details");
            return
        }
      const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/forgot-password`, {
        email,
        newPassword,
        answer,
      });
      console.log(res)
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        console.log(res)
        navigate("/login");
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

    return (
        <Layout title="Fashion Avenue - Login">
            <form>
            <div className='main' style={{color: 'white'}}>
                <div className='sub-main'>
                    <div>
                        <div>
                            <h1 className='LHeader m-5'>Reset Password</h1>
                            <div>
                                <input type="email" placeholder='Enter Email-id' className='fill input' value={email} onChange={(event) => setEmail(event.target.value) }/>
                            </div>
                            <div className='second-input'>
                                <input type="password" placeholder='Enter Password' className='fill input' value={newPassword} onChange={(event) => setNewPassword(event.target.value) }/>
                            </div>
                            <div className='second-input'>
                                <input type="text" placeholder='Enter Your Favourite Sports' className='fill input' value={answer} onChange={(event) => setAnswer(event.target.value) }/>
                            </div>
                            <div className='login-btn'>
                                    <button type="button" onClick={handleSubmit}>Reset Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </Layout>
  )
}

export default ForgotPassword