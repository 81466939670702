import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import Layout from '../components/layout/Layout'
import { useAuth } from '../context/auth';
import Person from "../assets/profile.png";
import ChangePassword from '../components/ChangePassword';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function Profile() {

  const [auth, setAuth] = useAuth();
  const [disable, setDisable] = useState(true)
  const [photo, setPhoto] = useState()
  const [profilephoto, setProfilephoto] = useState()


  // console.log("phnbbefbefb", photo)

  const [Info, setInfo] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    user: auth?.user
  });

  const [Password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  // console.log('user profile', Info)
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const getUser = () => {
    console.log(auth)
    setInfo({
      name: auth?.user?.name,
      email: auth?.user?.email,
      address: auth?.user?.address,
      phone: auth?.user?.phone
    })
  }

  useEffect(() => {
    getUser();
    getUserPhoto();
  }, [auth])

  const onUpdateHandler = async () => {
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/auth/profile`,
        Info
      );
      console.log(result);
      if (result.data.success) {
        toast.success(result.data.message);
        let ls = localStorage.getItem('auth');
        ls = JSON.parse(ls)
        ls.user = result?.data?.updatedUser
        localStorage.setItem('auth', JSON.stringify(ls))
        console.log(ls)
        setAuth({ ...auth, user: result.data.updatedUser })
      } else {
        toast.error(result.data.error);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getUserPhoto = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/auth/getprofile/${auth?.user?._id}`)
      console.log("profile", res)
      setProfilephoto(res?.data?.photo?.url)
    } catch (error) {
      console.log(error)
    }
  }

  const updateProfileImage = async () => {
    console.log("photo====", photo);
    try {
      if (photo) {
        const formData = new FormData();
        formData.append("photo", photo)
        const result = await axios.put(
          `${process.env.REACT_APP_API}/api/v1/auth/profilephoto/${auth?.user?._id}`,
          formData
        );
        console.log(result);
        if (result?.data?.success) {
          toast.success("Profile Photo Updated Successfully")
        }
        getUserPhoto();
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log("photo", photo)
    photo && updateProfileImage()
    getUserPhoto()
  }, [photo])

  const onChangePasswordHandler = async() => {
    if (!Password.oldPassword) {
      toast.error("Old Password is Required.")
      return
    }
    if (!Password.newPassword) {
      toast.error("New Password is Required.")
      return
    }
    if (Password.newPassword !== Password.confirmPassword) {
      toast.error("New Password and Confirm Password are not same.")
      return
    }
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/auth/change-password`,
        {
          oldPassword: Password.oldPassword,
          newPassword: Password.newPassword
        }
      );
      console.log(result);
      if (!result?.data?.success) {
        toast.error(result?.data?.message)
        return
      }
      if (result.data.success) {
        toast.success(result.data.message);
        let ls = localStorage.getItem('auth');
        ls = JSON.parse(ls)
        ls.user = result?.data?.updatedUser
        localStorage.setItem('auth', JSON.stringify(ls))
        console.log(ls)
        setAuth({ ...auth, user: result.data.updatedUser })
      } else {
        toast.error(result.data.error);
      }
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <Layout>
      <div className="row">
        <div className="col-md-12" style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h2>Profile</h2>

          <div className="ProfileInput">
            <img src={profilephoto || Person} alt="Profile Pic"
              style={{ borderRadius: "50%", border: "1px solid white", height: 120, width: 120 }} />
          </div>
          <div>
            <input type="file" id="upload-file" hidden onChange={(e) => setPhoto(e.target.files[0])} />
            <label htmlFor="upload-file">Change Profile Image</label>
          </div>
          {/* <Box sx={{ width: '100%', typography: 'body1' }}> */}
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Update Profile" value="1" />
                <Tab label="Change Password" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">

              {/* </Box> */}
              <div style={{ width: 300, margin: 20 }}>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  value={Info.name || ""}
                  disabled={disable}
                  onChange={(e) => setInfo({ ...Info, name: e.target.value })}
                />
              </div>
              <div style={{ width: 300, margin: 20 }}>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter E-mail"
                  disabled
                  value={Info.email || ""}
                  onChange={(e) => setInfo({ ...Info, email: e.target.value })}
                />
              </div>
              <div style={{ width: 300, margin: 20 }}>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  name="phone"
                  disabled={disable}
                  placeholder="Enter Phone"
                  value={Info.phone || ""}
                  onChange={(e) => setInfo({ ...Info, phone: e.target.value })}
                />
              </div>
              <div style={{ width: 300, margin: 20 }}>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  disabled={disable}
                  placeholder="Enter Address"
                  value={Info.address || ""}
                  onChange={(e) => setInfo({ ...Info, address: e.target.value })}
                />
              </div>
              {/* <div style={{ width: 300, margin: 20 }}>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  disabled={disable}
                  placeholder="Enter Password"
                  value={Info.password || ""}
                  onChange={(e) => setInfo({ ...Info, password: e.target.value })}
                />
              </div> */}
              <div className='text-center' style={{ width: 300, margin: 20 }}>
              {!disable ? (
                <button type="button" className="btn btn-success" onClick={onUpdateHandler}>Update</button>
              ) : (
                <button type="button" className="btn btn-primary" onClick={() => setDisable(false)}>Update</button>
              )}
              </div>
            </TabPanel>
            <TabPanel value="2">
            <div style={{ width: 300, margin: 20 }}>
                <input
                  type="text"
                  className="form-control"
                  id="oldpassword"
                  name="oldpassword"
                  placeholder="Enter Old Password"
                  value={Password.oldPassword || ""}
                  onChange={(e) => setPassword({ ...Password, oldPassword: e.target.value })}
                />
              </div>
              <div style={{ width: 300, margin: 20 }}>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  value={Password.newPassword || ""}
                  onChange={(e) => setPassword({ ...Password, newPassword: e.target.value })}
                />
              </div>
              <div style={{ width: 300, margin: 20 }}>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  value={Password.confirmPassword || ""}
                  onChange={(e) => setPassword({ ...Password, confirmPassword: e.target.value })}
                />
              </div>
              <div className='text-center' style={{ width: 300, margin: 20 }}>
                <button type="button" className="btn btn-success" onClick={onChangePasswordHandler}>Change Password</button>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </Layout>
  )
}

export default Profile