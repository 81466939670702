import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CartItem from '../components/CartItem';
import Layout from '../components/layout/Layout'
import ReviewModel from '../components/ReviewModel';
import Slider from '../components/slider/Slider';
import { useAuth } from '../context/auth';
import { useCart } from '../context/cart';
import PersonImg from "../assets/profile.png"
import Rating from "@mui/material/Rating";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

function ProductDetails() {

    const params = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [auth, setAuth] = useAuth()
    const [cart, setCart] = useCart();
    const [size, setSize] = useState("");
    const [review, setReview] = useState([]);
    const [reviewUniId, setReviewUniId] = useState([])
    const [rewEdit, setRewEdit] = useState("")
    const [rewUniOrderId, setRewUniOrderId] = useState([])
    const [id, setId] = useState("");
    const [open, setOpen] = useState(false)

    console.log("reviewUniId===", reviewUniId)
    console.log("rewUniOrderId===", rewUniOrderId)

    //initalp details
    useEffect(() => {
        if (params?.slug) getProduct();
    }, [params?.slug]);
    //getProduct
    const getProduct = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API}/api/v1/product/get-product/${params.slug}`
            );
            setProduct(data?.product);
            setId(data?.product?._id);
            console.log("idefef=====", data?.product?._id)
            console.log("single product", data.product)
            getSimilarProduct(data?.product._id, data?.product.category._id);
        } catch (error) {
            console.log(error);
        }
    };
    //get similar product
    const getSimilarProduct = async (pid, cid) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API}/api/v1/product/related-product/${pid}/${cid}`
            );
            setRelatedProducts(data?.products);
        } catch (error) {
            console.log(error);
        }
    };


    const onAddToCartHandler = async (item) => {
        try {
          console.log(auth, item)
          if (!auth.token) {
            toast.error("Please login to Add to Cart.")
            return
          }
          console.log(item)
          const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/cart/create-cart`, { products: item._id, user: auth?.user?._id, size, allSize: item.size});
          console.log(res)
          if (res.data.success) {
            console.log(res.data.message)
            toast.success(res.data.message)
          }
          if (!res.data.success) {
            console.log(res.data.message)
            toast.error(res.data.message)
          }
          getUserCart()
        } catch (error) {
          console.log(error)
        }
      }
    
      const getUserCart = async() => {
        try {
          const data = localStorage.getItem("auth");
          const userData = JSON.parse(data);
          // console.log(userData)
          const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/cart/get-cart/${userData.user._id}`)
          console.log(res)
          let cartData = [];
          res.data.cart.map((item) => {
            const price = item?.products?.price - ( item?.products?.discount || 0)
            cartData.push({
              cid: item._id,
              products: item.products,
              quantity: item.quantity,
              totalPrice: price * item.quantity,
              allSize: item.allSize,
              size: item.size
            })
            return cartData
          })
          console.log(cartData)
          setCart(cartData)
        } catch (error) {
          console.log(error)
        }
      }

      const getProductReview = async() => {
        try {
          if (id) {
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/review/get-review/${id}`)
            console.log('review=====', res)
    
            //get unique id for edit and delete review
            const data = res?.data?.reviews?.filter((item) => {
              return item?.user?._id === auth?.user?._id;
            })
            const uniId = data.map((item) => {
              return item?._id
            })
            setReviewUniId(uniId)
            setReview(res?.data?.reviews)
    
            //get user order for adding review
            const result = await axios.get(
              `${process.env.REACT_APP_API}/api/v1/auth/user-orders/${auth?.user?._id}`
            );
            console.log('user orders', result?.data);
            let orderProduct = []
            const order = result?.data?.map((item) => {
              item.products.map((p) => {
                orderProduct.push(p?.product)
                return orderProduct
              })
            })
            console.log('ordered product', orderProduct)
    
            const uniOrder = orderProduct.filter((item) => {
              return item?._id === id
            })
            const uniOrdId = uniOrder.map((item) => {
              return item?._id
            })
            console.log("uniOrder======>>>>>>", uniOrdId)
            setRewUniOrderId(uniOrdId)
          }
        } catch (error) {
          console.log(error)
        }
      }

      useEffect(() => {
        console.log("revvvvvvvvv====")
        getProductReview()
      }, [product, open])
    
      const AddReview = () => {
        setOpen(true)
      }
    
      const onUpdateReviewHandler = (id) => {
        setRewEdit(id)
        setOpen(true)
      }
      
      const onDeleteReviewHandler = async (id) => {
        try {
          const res = await axios.delete(`${process.env.REACT_APP_API}/api/v1/review/delete-review/${id}`)
          console.log(res)
          toast.success(res?.data?.message)
          getProductReview()
        } catch (error) {
          console.log(error)
        }
      }
    
      const handleClose = () => {
        setOpen(false)
        setRewEdit("")
      }

    return (
        <Layout>
            {/* <div className='container'> */}
            <div className="row container product-details m-auto">
                <div className="col-md-5">
                    {/* <img
                        src={product?.photo ? product.photo.url : ""}
                        className="card-img-top singleImg"
                        // style={{ height: 400, width: 400, margin: 20 }}
                        alt={product?.name}
                        height="400"
                        width={"350px"}
                    /> */}
                    <Slider images={product.photos} singlePhoto={product.photo}/>
                </div>
                
                <div className="col-md-7 product-details-info" style={{color: 'black'}}>
                    {/* <h2 className="text-center">Product Details</h2> */}
                    {/* <hr className='hr mt-0' style={{marginLeft: 270}}/> */}
                    <h3>{product.name}</h3>
                    <h4>{product.description}</h4>
                    {/* <h4>
                        ₹
                        {product?.price}
                    </h4> */}
                    {product?.discount ? (
                              <div className="d-flex">
                              <p className="card-text" style={{ fontSize: 20 }}>₹{product.price - product.discount} </p>
                              <p className="card-text discountPrice" style={{ fontSize: 15, }}> ₹{product.price}</p>
                              <p className="card-text" style={{ fontSize: 18, color: 'red' }}>{product.discountRate}%</p>
                              </div>
                            ) : (
                              <p className="card-text" style={{ fontSize: 18 }}>
                                ₹{product.price}
                            </p>
                            )}
                    <h4 className='m-0 p-0'>{product?.category?.name}</h4>
                    <Rating name="half-rating" value={product.totalStar / product.numOfReview} readOnly precision={0.5} />
                    <div>
                    <h5 className="mt-3">Select Size</h5>
                    {product?.size && product?.size.map((item, i) => {
                      return (
                        <>
                          <input type="radio" className="btn-check m-2" value={item} onChange={(e) => setSize(e.target.value)} name="options-outlined" id={`success-outlined ${i}`} />
                          <label className="btn btn-outline-danger m-1" htmlFor={`success-outlined ${i}`}>{item}</label>
                        </>
                      )
                    })}
                    {/* <select className="form-select my-1" aria-label="Default select example" 
                    onChange={(e) => setSize(e.target.value)}
                    value={size}
                      style={{width: 150, height: 40}}>
                      <option>Select Size</option>
                      {product?.size && product?.size.map((item) => {
                        return <option key={item} value={item}>{item}</option>
                      })}
                    </select> */}
                    </div>
                    <button className="btn btn-secondary my-1" style={{width: 150, background: "coral", border: 'none'}}
                    onClick={() => onAddToCartHandler(product)}>ADD TO CART</button>
                    <hr className='hr'/>
                    <p>{product.details}</p>
                    <p className='m-1'>100% Original Products</p>
                    <p className='m-1'>Pay on delivery might be available</p>
                    <p className='m-1'>Easy 14 days returns and exchanges</p>
                    <p className='m-1'>Try & Buy might be available</p>
                </div>
                
            
            <ReviewModel open={open} handleClose={handleClose} products={product} rewEdit={rewEdit} />
              <div className="col-md-8">
          <div style={{display: "flex",flexDirection: "column", alignItems: "start", marginTop: 20}}>
            <h4>Reviews</h4>
            <div className="row">
                {review.length ? review.map((item, i) => (<>
                    {/* <div className="row"> */}
                    <div key={i} className="col-10 px-0">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: review.length === 1 ? 350 : "auto" }}>
                        <div className="d-flex mt-3 mb-0">
                          <img src={item?.user?.photo?.url || PersonImg} alt="profile" style={{ borderRadius: 20, width: 35, height: 35 }} />
                          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            <h5 style={{ marginLeft: 10, marginBottom: 0 }}>{item.user.name}</h5>
                            <p style={{marginLeft: 10, marginTop: 0, fontSize: 15, marginBottom: 6}}>{moment(item.createdAt).fromNow()}</p>
                          </div>
                        </div>
                          <Rating
                            name="half-rating"
                            value={item.star}
                            readOnly
                            precision={0.5}
                          />
                          <p>{item.desc}</p>
                    </div>
                  </div>
                  <div className="col-2 mt-3 pl-0 pr-0">
                    {
                      item._id === reviewUniId[0] ? (
                        <>
                          <DeleteIcon onClick={() => onDeleteReviewHandler(item._id)} />
                          <EditIcon onClick={() => onUpdateReviewHandler(item._id)}/>
                        </>)  : ""
                    }
                    {/* </div> */}
                    </div>
                  </>
            )) : <p>No Review on this Product</p>}
                {!reviewUniId.length ? ( rewUniOrderId.length ? (<button
                    type="button"
                    className="btn btn-warning"
                    style={{width: 120}}
                    onClick={() => AddReview(product)}
                >
                  Add Review
                </button>) : "" ) : ""}
                </div>
            </div>
            </div>
            </div>
            <hr />
            <div className="row mx-auto container-fluid similar-products">
                <h2 className='text-center'>Similar Products</h2>
                <hr style={{width: "50px",height: "2px",margin: "auto", backgroundColor: "coral", opacity: 1, marginBottom: 10}}/>
                {/* {relatedProducts.length < 1 && (
                    <p className="text-center">No Similar Products found</p>
                )} */}
                <div className="d-flex flex-wrap justify-content-center">
                    {/* {relatedProducts.length ? (
                        relatedProducts.map((p) => (
                        <div key={p._id} className="product text-center col-lg-3 col-md-6 col-12">
                        <div onClick={() => navigate(`/product/${p.slug}`)}>
                        <img style={{ height: 300, width: 300 }} className="img-fluid mb-3" src={`${process.env.REACT_APP_API}/api/v1/product/product-photo/${p._id}`} alt="" />
                        <h5 className="p-name">{p.name}</h5>
                        <p className="p-name" style={{ color: 'gray' }}>{p.description}</p>
                        <h4 className="p-price">Rs. {p.price}</h4>
                        </div>
                        <button className="buy-btn">Buy Now</button>
                        <button className="buy-btn m-2" style={{background: 'skyblue'}} onClick={() => onAddToCartHandler(p)}>Add to Cart</button>
                        </div>
                    ))) : <p className='text-center'>No Product Found</p>} */}

                    <CartItem products={relatedProducts}/>
                </div>
            </div>
            {/* </div> */}
        </Layout>
    )
}

export default ProductDetails