import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Header from './Header'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Toaster } from 'react-hot-toast';

function Layout(props) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta name="author" content={props.author} />
        <title>{props.title}</title>
      </Helmet>
        <Header />
        <main style={{minHeight: '80vh', marginTop: '80px'}}>
          <ToastContainer />
          {/* <Toaster /> */}
            {props.children}
        </main>
        <Footer />
    </div>
  )
}

Layout.defaultProps = {
  title: "Fashion Avenue - shop now",
  description: "E commerce website",
  keywords: "ecommerce website",
  author: "student",
};

export default Layout