import Layout from '../components/layout/Layout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
// import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/auth';
// import mail from "./images/email.png";
// import lock from "./images/lock.png";
// import profile from "./images/icon.jpg";

function Register() {

    const [Info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
        answer: ""
    });

    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();

    let register = async (e) => {
        e.preventDefault()

        try {
            if (!Info.name || !Info.email || !Info.password || !Info.phone || !Info.address || !Info.answer) {
                toast.error("Complete all the fields!!!")
                return
            }
            if ( Info.password.length < 6) {
                console.log('<<<<<<')
                toast.error("Minimum 6 digits of password required.")
                return 
            }
            // console.log(process.env.REACT_APP_API)
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/register`,Info);
            if (res && res.data.success) {
                console.log(res)
                toast.success(res.data.message);
                toast.success('Register Successfully')
                setAuth({
                    ...auth,
                    user: res?.data?.user,
                    token: res?.data?.token,
                  });
                  localStorage.setItem("auth", JSON.stringify(res.data));
                navigate("/");
            } else {
              toast.error(res.data.message);
            }
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
    }
    return (
        <Layout title="Fashion Avenue - Register">
            <form onSubmit={register}>
                <div className='main' style={{color: 'white'}}>
                    <div className='sub-mainR'>
                        <div>
                            <div>
                                <h1>Registration</h1>
                                <div>
                                    {/* <img src={profile} alt="emial" className='email' /> */}
                                    <input type="text" placeholder='Enter Name' className='fill input' value={Info.name} onChange={(e) => setInfo({ ...Info, name: e.target.value })} />
                                </div>
                                <div className='mail-id'>
                                    {/* <img src={mail} alt="emial" className='email' /> */}
                                    <input type="email" placeholder='Enter Email-id' className='fill input' value={Info.email} onChange={(e) => setInfo({ ...Info, email: e.target.value })} />
                                </div>
                                <div className='mail-id'>
                                    {/* <img src={profile} alt="emial" className='email' /> */}
                                    <input type="text" placeholder='Enter Phone Number' className='fill input' value={Info.phone} onChange={(e) => setInfo({ ...Info, phone: e.target.value })} />
                                </div>
                                <div className='mail-id'>
                                    {/* <img src={profile} alt="emial" className='email' /> */}
                                    <input type="text" placeholder='Enter Address' className='fill input' value={Info.address} onChange={(e) => setInfo({ ...Info, address: e.target.value })} />
                                </div>
                                <div className='mail-id'>
                                    {/* <img src={profile} alt="emial" className='email' /> */}
                                    <input type="text" placeholder='Enter Your Favourite Sport' className='fill input' value={Info.answer} onChange={(e) => setInfo({ ...Info, answer: e.target.value })} />
                                </div>
                                <div className='mail-id'>
                                    {/* <img src={lock} alt="emial" className='email' /> */}
                                    <input type="password" placeholder='Enter Password' className='fill input' value={Info.password} onChange={(e) => setInfo({ ...Info, password: e.target.value })} />
                                </div>
                                <div className='login-btn'>
                                        <button type="submit">Register</button>
                                </div>
                                <div className='reg-link'>
                                    <p>Already have an Account ? </p><Link className='link forgot-password' to='/login'><li>Login</li></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )

}

export default Register