import { Rating } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../context/auth';
import { useCart } from '../context/cart';
import WishlistC from './WishlistC';

function CartItem({products}) {
    const [wishlist, setWishlist] = useState([]);
    const [auth, setAuth] = useAuth();
    const [cart, setCart] = useCart();

    const navigate = useNavigate()
    // console.log("wishlist", wishlist)

    const getUserCart = async() => {
        try {
          const data = localStorage.getItem("auth");
          const userData = JSON.parse(data);
          // console.log(userData)
          const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/cart/get-cart/${userData.user._id}`)
          console.log(res)
          let cartData = [];
          res.data.cart.map((item) => {
            const price = item?.products?.price - ( item?.products?.discount || 0)
            cartData.push({
              cid: item._id,
              products: item.products,
              quantity: item.quantity,
              totalPrice: price * item.quantity,
              allSize: item.allSize,
              size: item.size
            })
            return cartData
          })
          console.log(cartData)
          setCart(cartData)
        } catch (error) {
          console.log(error)
        }
      }


    const onAddToCartHandler = async (item) => {
        try {
          console.log(auth, item)
          if (!auth.token) {
            toast.error("Please login to Add to Cart.")
            return
          }
          const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/cart/create-cart`, { products: item._id, user: auth?.user?._id, allSize: item.size});
          console.log(res)
          if (res.data.success) {
            console.log(res.data.message)
            toast.success(res.data.message)
          }
          if (!res.data.success) {
            console.log(res.data.message)
            toast.error(res.data.message)
          }
          getUserCart()
        } catch (error) {
          console.log(error)
        }
      }

  return (
    
    <>
        {products.length ? (
             products.map((p,i) => {
              let removeId = "";
              let isliked;
              {isliked = wishlist && wishlist.filter((item) => {
                  if (item?.product?._id === p?._id) {
                    removeId = item.id;
                  }
                  return item?.product?._id === p?._id
              })}
              return (
            <div key={i} className="product text-center col-lg-3 col-md-6 col-12" >
              <div onClick={() => navigate(`/product/${p.slug}`)}>
              <div className='img-fluid imghoverCont'>
                <img style={{ height: 300, width: 300 }} className="img-fluid mb-3 imghover" src={p.photo ? p.photo.url : ""} alt="" />
              </div>
              <h5 className="p-name">{p.name}</h5>
              <p className="p-name" style={{ color: 'gray' }}>{p.description.slice(0,30)}</p>
              {/* <h4 className="p-price">Rs. {p.price}</h4> */}
              <Rating name="half-rating" value={p.totalStar / p.numOfReview} readOnly precision={0.5} />
              {p?.discount ? (
                              <div className="d-flex" style={{justifyContent: "center"}}>
                              <p className="card-text" style={{ fontSize: 18 }}>₹{(p.price - p.discount).toFixed(2)} </p>
                              <p className="card-text discountPrice" style={{ fontSize: 15, }}> ₹{p?.price}</p>
                              <p className="card-text" style={{ fontSize: 18, color: 'red' }}>{p.discountRate}%</p>
                              </div>
                            ) : (
                              <p className="card-text" style={{ fontSize: 18 }}>
                                ₹{(p.price).toFixed(2)}
                            </p>
                            )}
              </div>
              
              {/* <button className="buy-btn m-2">Buy Now</button> */}
              <button className="buy-btn m-2" style={{background: '#5dc2ea'}} onClick={() => onAddToCartHandler(p)}>Add to Cart</button>
              <WishlistC item={p} isliked={isliked} removeId={removeId} setWishlist={setWishlist}/>
              {/* {isliked.length ? 
              ( <FavoriteIcon style={{color: 'red'}} onClick={() => onRemoveWishlist(removeId)}/> ) : (
                <FavoriteBorderIcon onClick={() => onAddWishlistHandler(p)}/>
              )} */}
            </div>
          )})) : <p className='text-center'>No Product Found</p>}
    </>
  )
}

export default CartItem