import Layout from '../components/layout/Layout'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Checkbox, Form, Radio } from "antd";
import { useNavigate } from 'react-router-dom'
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { Prices } from '../components/Prices';
import TuneIcon from '@mui/icons-material/Tune';
import SearchInput from '../components/SearchInput';
import SearchIcon from '@mui/icons-material/Search';
import Loading from '../components/Loading';
import { toast } from 'react-toastify';
import { useAuth } from '../context/auth';
import { useCart } from '../context/cart';
import WishlistC from '../components/WishlistC';
import CartItem from '../components/CartItem';

function Shop() {

    const navigate = useNavigate();
    // const [cart, setCart] = useCart();
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [checked, setChecked] = useState([]);
    const [radio, setRadio] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchIcon, setSearchIcon] = useState(false)
    const [auth, setAuth] = useAuth()
    const [cart, setCart] = useCart();

    // console.log(products)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getAllCategory = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/category/get-category`);
            if (data?.success) {
                setCategories(data?.category);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllCategory();
        getTotal();
    }, []);

    //getTOtal COunt
    const getTotal = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/product-count`);
            setTotal(data?.total);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (page === 1) return;
        loadMore();
    }, [page]);
    //load more
    const loadMore = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/product-list/${page}`);
            setLoading(false);
            setProducts([...products, ...data?.products]);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // filter by cat
    const handleFilter = (value, id) => {
        let all = [...checked];
        if (value) {
            all.push(id);
        } else {
            all = all.filter((c) => c !== id);
        }
        setChecked(all);
    };
    useEffect(() => {
        if (!checked.length && !radio.length) getAllProducts();
    }, [checked.length, radio.length]);

    useEffect(() => {
        if (checked.length || radio.length) filterProduct();
    }, [checked, radio]);

    //get filterd product
    const filterProduct = async () => {
        try {
            setLoading(true)
            const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/product/product-filters`, {
                checked,
                radio,
            });
            setProducts(data?.products);
            console.log(data.products)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    //get products
    const getAllProducts = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/product/product-list/${page}`);
            setLoading(false);
            setProducts(data.products);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const onClearFilter = () => {
        setChecked([])
        setRadio([]);
        setAnchorEl(null)
    }


    return (
        <Layout>
            <section id="featured" className="my-3 pb-5">
                <div className="text-center mt-5 py-5 mr-5">
                    
                    <h3>Our Products</h3>
                    <hr className="mx-auto hr" />
                    <p>Here you can check out our new products with fair price on Fashion Avenue.</p>
                    <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'end' }}>
                    {!searchIcon && <SearchIcon onClick={() => setSearchIcon(true)}/> }
                    {searchIcon && <SearchInput /> }
                        <div style={{marginTop: 10}}>
                            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                                Filter  <TuneIcon />
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <div className="m-2" style={{ display: 'flex', flexDirection: 'column', width: 200, height: 250 }}>
                                    <h4>Filter</h4>
                                    <h5>By Category</h5>
                                    {categories?.map((c) => (
                                        <Checkbox
                                            key={c._id}
                                            style={{ marginLeft: 9, fontSize: 16 }}
                                            onChange={(e) => handleFilter(e.target.checked, c._id)}
                                            checked={checked.includes(c._id)}
                                        >
                                            {c.name}
                                        </Checkbox>
                                    ))}

                                    <h5 className='mt-3'>By Price</h5>
                                    <Form>
                                        <Form.Item name="radio">
                                            {/* <Radio.Group onChange={(e) => setRadio(e.target.value)}> */}
                                            <div>
                                                {Prices?.map((p, i) => (
                                                    <div key={i}>
                                                        <Radio onChange={(e) => setRadio(e.target.value)} checked={radio === p.array} style={{ marginLeft: 9, fontSize: 16 }} value={p.array}>{p.name}</Radio>
                                                    </div>
                                                ))}
                                                {/* </Radio.Group> */}
                                            </div>
                                        </Form.Item>
                                    </Form>
                                    <button style={{ margin: 10, width: '100px' }} type="button" onClick={onClearFilter} className="btn btn-danger">Clear</button>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
                <div className="row mx-auto container-fluid">
                <div style={{marginLeft: 650}}>
                    {loading && <Loading />}
                </div>
                    {/* {products.length ? (
                        products.map((p) => {
                            let removeId = "";
                            let isliked;
                            {isliked = wishlist.filter((item) => {
                                if (item.product._id === p._id) {
                                    removeId = item.id;
                                }
                                console.log('item', item.id,  removeId)
                                return item.product._id === p._id
                            })}
                            return (
                            <div key={p._id} className="product text-center col-lg-3 col-md-6 col-12">
                                <div onClick={() => navigate(`/product/${p.slug}`)}>
                                <img style={{ height: 300, width: 300 }} className="img-fluid mb-3" src={`${process.env.REACT_APP_API}/api/v1/product/product-photo/${p._id}`} alt="" />
                                <h5 className="p-name">{p.name}</h5>
                                <p className="p-name" style={{ color: 'gray' }}>{p.description}</p>
                                <h4 className="p-price">Rs. {p.price}</h4>
                                </div>
                                <button className="buy-btn">Buy Now</button>
                                <button className="buy-btn m-2" style={{background: 'skyblue'}} onClick={() => onAddToCartHandler(p)}>Add to Cart</button>
                                <WishlistC item={p} isliked={isliked} removeId={removeId} setWishlist={setWishlist}/>
                            </div>
                        )})) : <p className='text-center'>No Product Found</p>} */}
                        <CartItem products={products}/>

                </div>
                <div className="m-2 p-3 text-center">
                    {!checked.length && !radio.length && products.length < total && (
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage(page + 1);
                            }}
                        >
                            {loading ? (
                                "Loading ..."
                            ) : (
                                <>
                                    {" "}
                                    Loadmore
                                </>
                            )}
                        </button>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default Shop